import styled from 'styled-components';


export const WrapperWaveDesktop = styled.div`
  position: absolute;
  left: 0;
  z-index: 1;
  height: 100%;
`;

export const WrapperWaveMobile = styled.div`
  position: absolute;
  bottom: -10px;
  z-index: 1;
  width: 100%;
`;
