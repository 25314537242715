import React from 'react';
import PropTypes from 'prop-types';
import AppMenu from '../app-menu';
import AppIndicators from '../indicators';
import * as S from './style';

// export const AppWrapper = ({ children, profileImage, setProfileImage }) => (
export const AppWrapper = ({ children }) => (
  <>
    <AppMenu />
    <AppIndicators />
    <S.ChildrenWrapper>{children}</S.ChildrenWrapper>
    <S.Bottom />
  </>
);

export const AppContainer = ({ children, className }) => (
  <S.Container className={className}>{children}</S.Container>
);

AppWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  // profileImage: PropTypes.object.isRequired,
  // setProfileImage: PropTypes.func.isRequired,
};

AppContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
AppContainer.defaultProps = { className: null };
