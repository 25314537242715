/* eslint-disable */
import { v4 as uuidv4 } from 'uuid';

const INICIO = {
  id: uuidv4(),
  delay: [1000, 3000],
  messageType: 'no-reply',
  inputProps: {
    disabled: true,
  },
  text: ['Olá, Temos algumas perguntas bem simples e rápidas para você'],
};

const ESCOLHA_A_REDE_SOCIAL = {
  id: uuidv4(),
  messageType: 'social-options',
  delay: [1000, 3000],
  text: [
    'Escolha uma das redes sociais para darmos continuidade',
    'Escolha novamente uma das redes sociais para continuar',
  ],
  inputProps: {
    disabled: true,
  },
  options: [
    // {
    //   label: 'Google',
    //   value: 'Google',
    //   bgColor: 'pink',
    // },
    {
      label: 'Facebook',
      value: 'Facebook',
      bgColor: 'pink',
    },
  ],
};

const INICIO_CONVENCIONAL = {
  id: uuidv4(),
  messageType: 'no-reply',
  delay: [1000, 3000],
  inputProps: {
    disabled: true,
  },
  text: [
    'Beleza, iremos fazer algumas perguntas e logo você estará cadastrado!',
  ],
};

const TIPO_CADASTRO = {
  id: uuidv4(),
  delay: [1000, 2000],
  messageType: 'options',
  text: ['Você quer o cadastro por redes sociais ou o convencional'],
  inputProps: {
    disabled: true,
  },
  options: [
    {
      label: 'Redes sociais',
      value: ESCOLHA_A_REDE_SOCIAL,
      bgColor: 'pink',
    },
    {
      label: 'Convencional',
      value: INICIO_CONVENCIONAL,
      bgColor: 'yellow',
    },
  ],
};

const TERMO_DE_USO = {
  id: uuidv4(),
  messageType: 'options',
  delay: [1000, 3000],
  inputProps: {
    disabled: true,
  },
  variables: ['name'],
  text: [
    'Olá %name%, seja muito bem-vindo ao Uffa! Leia agora os termos de uso, dê o concordar para finalizar o seu cadastro!',
    'Certo %name%! Leia os termos, dê o concordar para finalizar o seu cadastro!',
  ],
  options: [
    {
      label: 'Ler os termos de uso',
      value: 'read_term',
      bgColor: 'pink',
    },
  ],
};

const ACEITE_TERMOS = {
  id: uuidv4(),
  messageType: 'reply',
  delay: [1000, 3000],
  inputProps: {
    type: 'text',
    name: 'agree',
  },
  variables: ['name'],
  text: ['%name%, você concorda com os termos de uso?'],
};

const AGRADECIMENTO_CADASTRO = {
  id: uuidv4(),
  messageType: 'no-reply',
  delay: [1000, 3000],
  inputProps: {
    type: 'text',
    name: 'done',
  },
  variables: ['name'],
  text: ['%name%, muito obrigado por se cadastrar!'],

  /* id: uuidv4(),
  messageType: 'no-reply',
  delay: [1000, 3000],
  inputProps: {
    disabled: true,
  },
  variables: ['name'],
  text: ['%name%, muito obrigado por se cadastrar!'], 


  id: uuidv4(),
  messageType: 'reply',
  delay: [1000, 3000],
  editableBallon: {
    field: 'companyName',
  },
  inputProps: {
    type: 'text',
    name: 'companyName',
  },
  text: [
    'Beleza, qual o seu nome fantasia?',
    'Beleza, qual o seu nome fantasia?',
  ],*/
};

const REDE_SOCIAL_ERROR = {
  id: uuidv4(),
  messageType: 'no-reply',
  delay: [1000, 3000],
  customMessage: true,
  //callback: () => goToMessage(ESCOLHA_A_REDE_SOCIAL_ID, 1),
  inputProps: {
    disabled: true,
  },
  text: ['Ops! Ocorreu um erro ao entrar com a rede social'],
};

const CONFIRMATION_CODE_ERROR = {
  id: uuidv4(),
  messageType: 'no-reply',
  delay: [1000, 3000],
  customMessage: true,
  //callback: () => goToMessage(ESCOLHA_A_REDE_SOCIAL_ID, 1),
  inputProps: {
    disabled: true,
  },
  text: ['Código informado inválido.'],
};


const CPFCNPJ_CADASTRADO = {
  id: uuidv4(),
  messageType: 'reply-options',
  delay: [1000, 3000],
  options: [
    {
      label: 'Esqueci minha senha',
      value: 'remember_password',
      bgColor: 'pink',
    },
  ],
  editableBallon: {
    field: 'cpfCnpj',
  },
  inputProps: {
    type: 'text',
    name: 'cpfCnpj',
  },
  text: [
    'Este CPF/CNPJ já está cadastrado, caso você tenha esquecido sua senha, clique no botão abaixo ou digite um novo CPF/CNPJ.',
    'Já existe um cadastro com este CPF/CNPJ, se você esqueceu sua senha, clique no botão abaixo ou digite um novo CPF/CNPJ.',
  ],
};

const TELEFONE_CADASTRADO = {
  id: uuidv4(),
  messageType: 'reply-options',
  delay: [1000, 3000],
  options: [
    {
      label: 'Esqueci minha senha',
      value: 'remember_password',
      bgColor: 'pink',
    },
  ],
  editableBallon: {
    field: 'cellphone',
  },
  inputProps: {
    type: 'text',
    name: 'cellphone',
  },
  text: [
    'Este celular já está cadastrado, caso você tenha esquecido sua senha, clique no botão abaixo ou digite um novo celular.',
    'Já existe um cadastro com este celular, se você esqueceu sua senha, clique no botão abaixo ou digite um novo celular.',
  ],
};

const EMAIL_CADASTRADO = {
  id: uuidv4(),
  messageType: 'reply-options',
  delay: [1000, 3000],
  options: [
    {
      label: 'Esqueci minha senha',
      value: 'remember_password',
      bgColor: 'pink',
    },
  ],
  editableBallon: {
    field: 'email',
  },
  inputProps: {
    type: 'text',
    name: 'email',
  },
  text: [
    'Este e-mail já está cadastrado, caso você tenha esquecido sua senha, clique no botão abaixo ou digite um novo e-mail.',
    'Já existe um cadastro com este e-mail, se você esqueceu sua senha, clique no botão abaixo ou digite um novo e-mail.',
  ],
};

const INFORME_SEU_NOME = {
  id: uuidv4(),
  messageType: 'reply',
  delay: [1000, 3000],
  editableBallon: {
    field: 'name',
  },
  validatorRegex: /[]/g,
  inputProps: {
    type: 'text',
    name: 'name',
    replaceEditorRegex: /[^a-zA-Z áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]/g,
  },
  text: ['Bom, como poderiamos te chamar?'],
};

const BEMVINDO = {
  id: uuidv4(),
  messageType: 'no-reply',
  delay: [1000, 3000],
  inputProps: {
    disabled: true,
  },
  variables: ['name'],
  text: ['Olá %name%, seja bem-vindo!', 'Seja bem-vindo %name%!'],
};

const NOME_ALTERADO = {
  id: uuidv4(),
  messageType: 'no-reply',
  customMessage: true,
  delay: [1000, 3000],
  variables: ['name'],
  text: [
    'Seu nome foi alterado para: %name%',
    'Seu nome foi alterado para: %name%',
  ],
};

const NOME_FANTASIA_ALTERADO = {
  id: uuidv4(),
  messageType: 'no-reply',
  customMessage: true,
  delay: [1000, 3000],
  variables: ['companyName'],
  text: [
    'Nome fantasia alterado para: %companyName%',
    'Nome fantasia alterado para: %companyName%',
  ],
};

const TELEFONE_ALTERADO = {
  id: uuidv4(),
  messageType: 'no-reply',
  customMessage: true,
  delay: [1000, 3000],
  variables: ['cellphone'],
  text: [
    'Seu telefone foi alterado para: %cellphone%',
    'Seu telefone foi alterado para: %cellphone%',
  ],
};

const EMAIL_ALTERADO = {
  id: uuidv4(),
  messageType: 'no-reply',
  customMessage: true,
  delay: [1000, 3000],
  variables: ['email'],
  text: [
    'Seu e-mail foi alterado para: %email%',
    'Seu e-mail foi alterado para: %email%',
  ],
};

const REDE_SOCIAL_NOME_USUARIO_ALTERADO = {
  id: uuidv4(),
  messageType: 'no-reply',
  customMessage: true,
  delay: [1000, 3000],
  variables: ['socialUser', 'socialPlatform'],
  text: [
    'Seu nome de usuário do %socialPlatform% foi alterado para: %socialUser%',
    'Seu nome de usuário do %socialPlatform% foi alterado para: %socialUser%',
  ],
};

const CPFCNPJ_ALTERADO = {
  id: uuidv4(),
  messageType: 'no-reply',
  customMessage: true,
  delay: [1000, 3000],
  variables: ['cpfCnpj'],
  text: [
    'Seu CPF/CPNJ foi alterado para: %cpfCnpj%',
    'Seu CPF/CPNJ foi alterado para: %cpfCnpj%',
  ],
};

const INFORME_SEU_CPFCNPJ = {
  id: uuidv4(),
  messageType: 'reply',
  delay: [1000, 3000],
  editableBallon: {
    field: 'cpfCnpj',
  },
  maskCpf: (text) => {
    text = text.replace(/[^\d]/g, '');
    text = text.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    return text;
  },
  maskCnpj: (text) => {
    text = text.replace(/[^\d]/g, '');
    text = text.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5'
    );
    return text;
  },
  inputProps: {
    type: 'text',
    name: 'cpfCnpj',
    replaceEditorRegex: /[^0-9]/g,
  },
  text: [
    'Informe agora seu CPF ou CNPJ',
    'Precisamos do seu CPF ou CNPJ para continuar com o cadastro',
  ],
};

const CPFCNPJ_INVALIDO = {
  id: uuidv4(),
  messageType: 'no-reply',
  delay: [1000, 3000],
  customMessage: true,
  text: ['CPF/CNPJ inválido.', 'CPF/CNPJ inválido.'],
};

const CONFIRMACAO_SENHA_INVALIDA = {
  id: uuidv4(),
  messageType: 'no-reply',
  delay: [1000, 3000],
  customMessage: true,
  text: [
    'Senha e confirmação devem ser iguais.',
    'Senha e confirmação devem ser iguais.',
  ],
};

const REDIRECIONAMENTO_CASHBACK = {
  id: uuidv4(),
  messageType: 'no-reply',
  delay: [1000, 3000],
  callback: null,
  inputProps: {
    disabled: true,
  },
  text: [
    'Aguarde, já estamos te redirecionando, agora você vai conhecer nosso portal de Cashback e todas as vantages de ser um Uffaencer!',
  ],
};

const TELA_ESQUECI_SENHA = {
  id: uuidv4(),
  messageType: 'no-reply',
  delay: [1000, 3000],
  callback: null,
  inputProps: {
    disabled: true,
  },
  text: ['Fim do chat, abre a tela de esqueci senha....'],
};

const INFORME_NOME_FANTASIA = {
  id: uuidv4(),
  messageType: 'reply',
  delay: [1000, 3000],
  editableBallon: {
    field: 'companyName',
  },
  inputProps: {
    type: 'text',
    name: 'companyName',
  },
  text: [
    'Beleza, qual o seu nome fantasia?',
    'Beleza, qual o seu nome fantasia?',
  ],
};

const INFORME_SEU_TELEFONE = {
  id: uuidv4(),
  messageType: 'reply',
  delay: [1000, 3000],
  mask: (text) => {
    text = text.replace(/[^\d]/g, '');
    if (text.length === 11)
      text = text.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    if (text.length === 10)
      text = text.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    return text;
  },
  editableBallon: {
    field: 'cellphone',
  },
  inputProps: {
    type: 'text',
    name: 'cellphone',
    replaceEditorRegex: /[^0-9]/g,
    maxLength: 11,
  },
  text: [
    'Qual é seu telefone com DDD para contatos?',
    'Qual é seu telefone com DDD para contatos?',
  ],
};

const INFORME_SEU_EMAIL = {
  id: uuidv4(),
  messageType: 'reply',
  delay: [1000, 3000],
  editableBallon: {
    field: 'email',
  },
  inputProps: {
    type: 'text',
    name: 'email',
    replaceEditorRegex: /[^a-zA-Z0-9._%+-@]/g,
  },
  text: [
    'Vamos continuar, precisamos agora do seu email',
    'Vamos continuar, precisamos agora do seu email',
  ],
};

const REDE_SOCIAL_UTILIZADA = {
  id: uuidv4(),
  messageType: 'social-options',
  delay: [1000, 3000],
  editableBallon: {
    field: 'socialPlatform',
  },
  inputProps: {
    disabled: true,
  },
  options: [
    {
      label: 'Linkedin',
      value: 'Linkedin',
      bgColor: 'pink',
    },
    {
      label: 'Facebook',
      value: 'Facebook',
      bgColor: 'pink',
    },
    {
      label: 'Instagram',
      value: 'Instagram',
      bgColor: 'pink',
    },
    {
      label: 'Youtube',
      value: 'Youtube',
      bgColor: 'pink',
    },
    {
      label: 'Tiktok',
      value: 'Tiktok',
      bgColor: 'pink',
    },
    {
      label: 'Twitter',
      value: 'Twitter',
      bgColor: 'pink',
    },
  ],
  text: [
    'Bom, precisamos saber qual é a principal rede social que você usa?',
    'Bom, precisamos saber qual é a principal rede social que você usa?',
  ],
};

const REDE_SOCIAL_NOME_USUARIO = {
  id: uuidv4(),
  messageType: 'reply',
  delay: [1000, 3000],
  editableBallon: {
    field: 'socialUser',
  },
  inputProps: {
    type: 'text',
    name: 'socialUser',
  },
  variables: ['socialPlatform'],
  text: [
    'Beleza, você escolheu %socialPlatform%, qual o nome usado na rede social?',
    'Beleza, você escolheu %socialPlatform%, qual o nome usado na rede social?',
  ],
};

const SENHA = {
  id: uuidv4(),
  messageType: 'reply',
  delay: [1000, 3000],
  editableBallon: {
    field: 'password',
    type: 'password',
  },
  inputProps: {
    type: 'password',
    name: 'password',
  },
  text: [
    'Estamos quase acabando, agora precisamos de uma senha',
    'Estamos quase acabando, agora precisamos de uma senha',
  ],
};

const SENHA_CONFIRMACAO = {
  id: uuidv4(),
  messageType: 'reply',
  delay: [1000, 3000],
  editableBallon: {
    type: 'password',
  },
  inputProps: {
    type: 'password',
  },
  text: [
    'Repita a senha para confirmar, somente por segurança',
    'Repita a senha para confirmar, somente por segurança',
  ],
};

const ENVIAR_CODIGO_CONFIRMACAO = {
  id: uuidv4(),
  messageType: 'options',
  delay: [1000, 3000],
  editableBallon: {
    field: 'confirmationCode',
  },
  inputProps: {
    disabled: true,
  },
  options: [
    {
      value: 'email',
      label: 'E-mail',
      bgColor: 'pink',
    },
    {
      value: 'sms',
      label: 'Celular',
      bgColor: 'yellow',
    },
  ],
  text: [
    'Por onde deseja receber seu código de confirmação?',
    'Por onde deseja receber seu código de confirmação?',
  ],
};

const INFORMAR_CODIGO_CONFIRMACAO = {
  id: uuidv4(),
  messageType: 'reply-options',
  delay: [1000, 3000],
  inputProps: {
    type: 'text',
    name: 'confirmationCode',
  },
  variables: ['confirmationType', 'confirmationContact'],
  text: [
    'Acabamos de enviar um código de confirmação no seu %confirmationType% %confirmationContact%, digite abaixo',
    'Digite o código que enviamos no seu %confirmationType% %confirmationContact%, ou clique no botão abaixo para receber um novo código.',
  ],
  options: [
    {
      label: 'Reenviar código',
      value: 'resend_code',
      bgColor: 'pink',
    },
  ],
};

export const MessageFlow = {
  INICIO,
  TIPO_CADASTRO,
  ESCOLHA_A_REDE_SOCIAL,
  TERMO_DE_USO,
  AGRADECIMENTO_CADASTRO,
  REDE_SOCIAL_ERROR,
  CPFCNPJ_CADASTRADO,
  INICIO_CONVENCIONAL,
  INFORME_SEU_NOME,
  BEMVINDO,
  INFORME_SEU_CPFCNPJ,
  REDIRECIONAMENTO_CASHBACK,
  TELA_ESQUECI_SENHA,
  INFORME_NOME_FANTASIA,
  INFORME_SEU_TELEFONE,
  INFORME_SEU_EMAIL,
  REDE_SOCIAL_UTILIZADA,
  REDE_SOCIAL_NOME_USUARIO,
  SENHA,
  SENHA_CONFIRMACAO,
  ENVIAR_CODIGO_CONFIRMACAO,
  INFORMAR_CODIGO_CONFIRMACAO,
  CPFCNPJ_INVALIDO,
  NOME_ALTERADO,
  CPFCNPJ_ALTERADO,
  NOME_FANTASIA_ALTERADO,
  TELEFONE_ALTERADO,
  EMAIL_ALTERADO,
  REDE_SOCIAL_NOME_USUARIO_ALTERADO,
  CONFIRMACAO_SENHA_INVALIDA,
  EMAIL_CADASTRADO,
  TELEFONE_CADASTRADO,
  CONFIRMATION_CODE_ERROR,
  ACEITE_TERMOS,
};
