import styled, { css } from 'styled-components';

export const Flex = styled.div`
  display: flex;
  flex-direction: ${props => props.direction};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  flex: ${(props) => props.size};
  ${(props) =>
    props.hideOn &&
    props.theme.media[props.hideOn](css`
      display: none;
    `)}
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => props.column && 'column'};
`;
