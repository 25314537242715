import ServiceBase from './service.base';


function mountHeader(token) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export default class InfluencerRegisterService extends ServiceBase {
  postType(payload) {
    return this.post('/influencer', payload);
  }

  putType(payload) {
    return this.put('/influencer', payload);
  }

  async getStatus() {
    try {
      return await this.get('/influencer-onboarding');
    } catch (e) {
      if (e.type === 'INFLUENCER_DATA_NOT_FOUND') {
        return {};
      }
      throw e;
    }
  }

  getSocialNetworkData() {
    return this.get('/influencer-social-network');
  }

  postSocialNetworkData(payload) {
    return this.post('/influencer-social-network', payload);
  }

  async getBasicData(personType) {
    let data;
    try {
      if (personType?.toUpperCase() === 'PF') {
        data = await this.get('/influencer-personal-data');
        data.cpf = data.cpf.toString().padStart(11, '0');
        return data;
      }
      data = await this.get('/influencer-company-data');
      data.cnpj = data.cnpj.toString().padStart(15, '0');
      return data;
    } catch (e) {
      if (e.type === 'NOT_FOUND') {
        return null;
      }
      throw e;
    }
  }

  updatePersonalData(payload) {
    let method = 'post';
    if (payload.influencerId) {
      method = 'put';
    }
    delete payload.influencerId;
    return this[method]('/influencer-personal-data', payload);
  }

  async getProfileImage(token) {

    const config = { ...mountHeader(token) };

    try {
      return await this.get('/influencer/image', config);
    } catch (e) {
      if (e.type === 'NOT_FOUND' || e.type === 'INFLUENCER_DATA_NOT_FOUND') {
        return null;
      }
      throw e;
    }
  }

  putProfileImage(payload) {
    return this.put('/influencer/image', payload);
  }

  getDocument() {
    return this.get('/influencer-document');
  }

  postDocument(payload) {
    return this.post('/influencer-document', payload);
  }

  getDocumentResource(guid) {
    return this.get(`/influencer-document/${guid}`);
  }

  async getCompanyData() {
    try {
      return await this.get('/influencer-company-data');
    } catch (e) {
      if (e.type === 'NOT_FOUND') {
        return null;
      }
      throw e;
    }
  }

  updateCompanyData(payload) {
    let method = 'post';
    if (payload.influencerId) {
      method = 'put';
    }
    delete payload.influencerId;
    return this[method]('/influencer-company-data', payload);
  }

  async getAdvisorData() {
    try {
      const { data } = await this.get('/influencer-advisor');
      return data;
    } catch (e) {
      if (e.type === 'NOT_FOUND') {
        return null;
      }
      throw e;
    }
  }

  updateAdvisorData(payload) {
    let method = 'post';
    if (payload.influencerId) {
      method = 'put';
    }
    delete payload.influencerId;
    return this[method]('/influencer-advisor', payload);
  }

  deleteAdvisorData() {
    return this.delete('/influencer-advisor');
  }

  async getAddressData() {
    try {
      return await this.get('/influencer-address');
    } catch (e) {
      if (e.type === 'NOT_FOUND') {
        return null;
      }
      throw e;
    }
  }

  updateAddressData(payload) {
    let method = 'post';
    if (payload.influencerId) {
      method = 'put';
    }
    delete payload.influencerId;
    return this[method]('/influencer-address', payload);
  }

  async getAdditionalData() {
    try {
      return await this.get('/influencer-additional-data');
    } catch (e) {
      if (e.type === 'NOT_FOUND') {
        return null;
      }
      throw e;
    }
  }

  updateAdditionalData(payload) {
    let method = 'post';
    if (payload.influencerId) {
      method = 'put';
    }
    delete payload.influencerId;
    return this[method]('/influencer-additional-data', payload);
  }
}
