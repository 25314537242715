import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import * as S from './style';
import AvatarCat from '../../assets/images/cat_avatar.png';
import { Flex, Responsive } from '../layout';
import { ReactComponent as IconLogout } from '../../assets/icons/icon_logout.svg';
import { useOnboardingStatus } from '../../contexts/OnboardingStatusContext';
import Modal from '../modal';
import { Title, Paragraph } from '../text';
import { Button } from '../button';

const MenuList = [
  {
    label: 'Início',
    onClick: () => { },
    active: true,
    linkTo: ['/'],
    showMenu: true,
  },
  {
    label: 'Como funciona',
    onClick: () => { },
    active: false,
    showMenu: true,
    linkTo: ['/como-funciona'],
  },
  {
    label: 'Seja um Uffaencer',
    onClick: () => { },
    active: false,
    isUffaencer: false,
    linkTo: [
      '/cadastro-uffaencer',
      '/cadastro-uffaencer/sucesso',
      '/cadastro-uffaencer/reprovado',
    ],
  },
  {
    label: 'Downloads',
    onClick: () => { },
    active: false,
    isUffaencer: true,
    linkTo: ['/downloads'],
  },
  {
    label: 'Campanhas',
    onClick: () => { },
    isUffaencer: true,
    active: false,
    linkTo: ['/campanhas'],
  },
  {
    label: 'Meus Resultados',
    onClick: () => { },
    isUffaencer: true,
    active: false,
    linkTo: ['/meus-resultados'],
  },
  {
    label: 'Minha Conta',
    onClick: () => { },
    isUffaencer: true,
    active: false,
    linkTo: ['/minha-conta'],
  },
];

const AppMenu = ({ history }) => {
  const [isOpenMenu, setIsOpen] = React.useState(false);
  let alertDocumentPending = false;
  let alertDocumentRefused = false;
  const toggleMenu = () => setIsOpen(!isOpenMenu);
  const onboardingStatus = useOnboardingStatus();
  const { status, profileImage } = onboardingStatus;
  const icon = {};
  let textStatus;
  let url;

  const activeMenu = window.location.pathname;
  if (status && !status.notReady) {
    if (status.status === 'Pending' || status.status === 'Pending') {
      textStatus = 'Pendente';
      icon.pending = true;
      url = '/cadastro-uffaencer';
    } else if (status.status === 'Analyzing') {
      icon.analyzing = true;
      textStatus = 'Em análise';
      url = '/cadastro-uffaencer/sucesso';
    } else if (status.status === 'Pending Document') {
      icon.rejected = true;
      textStatus = 'Documentos pendentes';
      url = '/cadastro-uffaencer';
      if (activeMenu !== url) alertDocumentPending = true;
    } else if (status.status === 'Refused') {
      icon.rejected = true;
      textStatus = 'Cadastro rejeitado';
      url = '/cadastro-uffaencer/rejeitado';
      if (activeMenu !== url) alertDocumentRefused = true;
    } else if (['Approved', 'Active'].includes(status.status)) {
      icon.done = true;
      textStatus = 'Ativo';
      url = null;
    } else {
      icon.pending = true;
      textStatus = 'Pendente';
      url = '/cadastro-uffaencer';
    }
  } else {
    icon.pending = true;
    textStatus = 'Pendente';
    url = '/cadastro-uffaencer';
  }

  const handleStatusClick = () => {
    if (url) history.push(url);
    alertDocumentPending = false;
    alertDocumentRefused = false;
  };

  const handleLogoutClick = () => {
    sessionStorage.clear();
    history.push('/login');
  };

  const handleModal = () => {
    if (activeMenu !== url) history.push(url);
  };

  return (
    <>
      <S.MenuWrapper>
        <S.Menu>
          <S.LogoWrapper>
            <Link to="/">
              <S.Logo />
            </Link>
          </S.LogoWrapper>
          <Modal isOpen={alertDocumentPending || alertDocumentRefused}>
            <Title pink center medium>
              Atenção
            </Title>
            {alertDocumentPending ? (
              <Paragraph center margin="30px">
                Alguns documentos precisam da sua atenção, clique no botão
                abaixo para verificar suas pendências.
              </Paragraph>
            ) : (
              <Paragraph center margin="30px">
                Seu cadastro foi recusado, clique no botão abaixo para verificar
                suas pendências.
              </Paragraph>
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '36px',
              }}
            >
              <Button onClick={handleModal}>Verificar pendências</Button>
            </div>
          </Modal>
          <Responsive
            Desktop={() => (
              <S.MenuList>
                {MenuList.filter(
                  (f) =>
                    f.isUffaencer ===
                    ['Approved', 'Active'].includes(status?.status) ||
                    f.showMenu
                ).map((item) => (
                  <Link key={item.linkTo[0]} to={item.linkTo[0]}>
                    <S.MenuItem
                      active={item.linkTo.includes(activeMenu)}
                      key={item.label}
                    >
                      {item.label}
                      <S.MenuActiveBorder />
                      <S.MenuActiveDrops />
                    </S.MenuItem>
                  </Link>
                ))}
              </S.MenuList>
            )}
            Mobile={() => <></>}
          />

          <Responsive
            Desktop={() => (
              <S.MinhaContaWrapper>
                <S.Avatar src={profileImage?.base64 || AvatarCat} alt="Foto" />
                <S.StatusWrapper>
                  <S.Status url={url} onClick={handleStatusClick}>
                    <S.StatusIcon {...icon} />{' '}
                    <b style={{ marginRight: 5 }}>Status:</b> {textStatus}
                  </S.Status>
                  <div style={{ display: 'flex' }}>
                    {textStatus === 'Ativo' && (
                      <Link
                        to="/minha-conta"
                        style={{ textDecoration: 'none' }}
                      >
                        <S.LinkMinhaConta>Minha Conta</S.LinkMinhaConta>
                      </Link>
                    )}
                    <S.Logout onClick={handleLogoutClick}>
                      <IconLogout
                        width="16"
                        height="16"
                        style={{ marginRight: 5 }}
                      />
                      <span style={{ marginLeft: 3 }}>Sair</span>
                    </S.Logout>
                  </div>
                </S.StatusWrapper>
              </S.MinhaContaWrapper>
            )}
            Mobile={() => <S.MenuButton onClick={toggleMenu} />}
          />
        </S.Menu>
      </S.MenuWrapper>
      <S.MenuBorder />
      <Responsive
        Mobile={() => (
          <S.MenuList isOpenMenu={isOpenMenu}>
            {MenuList.filter(
              (f) =>
                f.isUffaencer ===
                ['Approved', 'Active'].includes(status?.status) || f.showMenu
            ).map((item) => (
              <Link
                key={item.linkTo[0]}
                to={item.linkTo[0]}
                onClick={() => setIsOpen(false)}
              >
                <S.MenuItem
                  style={{ margin: '0' }}
                  active={item.active}
                  key={item.label}
                >
                  {item.label}
                  <S.MenuActiveBorder />
                  <S.MenuActiveDrops />
                </S.MenuItem>
              </Link>
            ))}
            <Flex direction="row" hideOn="md-up">
              <S.MenuItem onClick={handleLogoutClick}>Sair</S.MenuItem>
            </Flex>
          </S.MenuList>
        )}
        Desktop={() => <></>}
      />
    </>
  );
};

AppMenu.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(AppMenu);
