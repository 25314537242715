import styled from 'styled-components';

export const Container = styled.div`

  background: #eee;
  padding: 60px;
  width: 100%;
  height: 100vh;
  h1 {
    font-size: 22px;
  }

`;

export const FormContainer = styled.div`

  width: 400px;
  margin: 0 auto;
`;