import ServiceBase from './service.base';

export default class TermService extends ServiceBase {
  async getUserTerm(type) {
    try {
      if (type) return await this.get(`/users-term/${type}`);
      return await this.get(`/users-term`);
    } catch (e) {
      if (e.type === 'NOT_FOUND') {
        return null;
      }
      return null;
      // throw e;
    }
  }

  postUserTerm(payload) {
    return this.post('/users-term', payload);
  }
}
