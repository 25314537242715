


import { createGlobalStyle } from "styled-components";

const Global = createGlobalStyle`
  html,
  body {
    height: 100%;
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  :root {
    font-family: 'Nunito', sans-serif;
    min-width: 330px;
  }

`;

export default Global;