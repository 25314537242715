import customAxios from './middleware';

export const getClientCredentials = () => customAxios.post('connect', {
  client_id: process.env.REACT_APP_CLIENT_ID,
  client_credentials: process.env.REACT_APP_CLIENT_CREDENTIALS,
});

export const login = async (payload) => customAxios.post('auth', payload, { headers: { isClientCredentials: true } });
export const loginSocial = async (payload) => customAxios.post('auth/social', payload, { headers: { isClientCredentials: true } });
export const forgotPassword = async (identityNumber) => customAxios.post(`/auth/forgot-password`, { identityNumber }, { headers: { isClientCredentials: true } })
export const forgotPasswordValidate = async (identityNumber, password, token) => customAxios.post(`/auth/forgot-password-validate`, { identityNumber, password, token }, { headers: { isClientCredentials: true } })