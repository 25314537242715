import React from 'react';
import PropTypes from 'prop-types';
import * as S from './style';

export const EnterAnimation = ({
  children,
  onTransitionEnd,
  direction,
  scaled,
  style,
}) => {
  const [enterClass, setEnterClass] = React.useState('');

  let timeout;
  React.useEffect(() => {
    timeout = setTimeout(() => setEnterClass(direction), 0);
    return () => clearTimeout(timeout);
  }, [direction]);

  const endTransition = () => {
    if (enterClass === 'out') {
      onTransitionEnd();
    }
  };

  return (
    <S.EnterAnimation
      style={style}
      scaled={scaled}
      className={enterClass}
      onTransitionEnd={endTransition}
    >
      {children}
    </S.EnterAnimation>
  );
};

EnterAnimation.defaultProps = {
  scaled: false,
  onTransitionEnd: () => {},
  style: {},
  direction: 'in',
};

EnterAnimation.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  onTransitionEnd: PropTypes.func,
  direction: PropTypes.oneOf(['in', 'out']),
  scaled: PropTypes.bool,
};
