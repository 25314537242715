/* eslint-disable indent */
import styled, { css } from 'styled-components';

const ModalBackdrop = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: #00000044;
  position: fixed;
  z-index: 200;
  top: 0;
  display: 'flex';
  transition: opacity 0.5s;
  z-index: 100;
  overflow: auto;
  opacity: 0;
  &.in {
    opacity: 1;
    .modal-wrapper {
      opacity: 1;
      ${(props) =>
        props.theme.media['md-up'](css`
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) scale(1);
        `)}

      ${(props) =>
        props.theme.media['sm-down'](css`
          left: 0;
          top: 0;
          transform: scale(1);
        `)}
    }
  }
  &.out {
    opacity: 0;
    .modal-wrapper {
      opacity: 0;
      ${(props) =>
        props.theme.media['md-up'](css`
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) scale(0);
        `)}
      ${(props) =>
        props.theme.media['sm-down'](css`
          left: 0;
          top: 0;
          transform: scale(0);
        `)}
    }
  }
`;

const ModalWrapper = styled.div`
  border-radius: 15px;
  background-color: #fff;
  position: absolute;
  transition: all 0.4s;
  opacity: 0;
  ${(props) =>
    props.theme.media['md-up'](css`
      width: 400px;
      min-height: 400px;
      transform: translate(-50%, -50%) scale(0);
    `)}
  ${(props) =>
    props.theme.media['sm-down'](css`
      min-width: 100%;
      min-height: 100vh;
      transform: scale(0);
    `)}
  ${(props) =>
    props.large &&
    css`
      ${props.theme.media['md-up'](css`
        min-width: 900px;
        padding: 0 60px;
        min-height: 400px;
        transform: translate(-50%, -50%) scale(0);
      `)}
    `}
    &.terms {
    padding: unset;
  }
`;

const ModalCloseButton = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  padding: 10px;
  width: 44px;
  height: 44px;
  border-radius: 22.5px;
  cursor: pointer;
  :hover {
    background-color: #00000022;
  }
`;

const ModalContent = styled.div`
  padding: 60px 30px 30px 30px;
  &.terms {
    padding: unset;
  }
`;

export { ModalBackdrop, ModalWrapper, ModalCloseButton, ModalContent };
