import React from 'react';
import PropTypes from 'prop-types';
import * as S from './style';
import { Paragraph, Bold } from '../../../../components/text';
import { EnterAnimation } from '../../../../components/animation';
import { Input } from '../../../../components/input';
import { Flex } from '../../../../components/layout';
import { Button } from '../../../../components/button';
import CloseIcon from '../../../../assets/icons/close.svg';
import LogoUffa from '../../../../assets/logo/uffa.svg';
import Loader from '../../../../components/loader';
import ErrorBox from '../../../../components/alert-error';

import {
  forgotPassword,
  forgotPasswordValidate,
} from '../../../../services/auth';

const RememberPassword = ({
  openRememberPasswordForm,
  scaledAnimation,
  openLoginForm,
}) => {
  const [rememberPassword, setRememberPassword] = React.useState({
    identityNumber: '',
    code: '',
    password: '',
    confirmationPassword: '',
  });

  const [phase, setPhase] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);

  const [animationClass, setAnimationClass] = React.useState('in');

  const [errorSend, setErrorSend] = React.useState(false);
  const [errorConfirmation, setErrorConfirmation] = React.useState(false);

  const sendCode = async () => {
    try {
      setIsLoading(true);

      await forgotPassword(rememberPassword.identityNumber.replaceAll('.', '').replace('-', ''));
      setIsLoading(false);
      setPhase(1);
    } catch (e) {
      setErrorSend('Ocorreu um erro ao enviar o código');
      setIsLoading(false);
    }
  };

  const confirmationCode = async () => {
    try {
      if (rememberPassword.password !== rememberPassword.confirmationPassword) {
        setErrorConfirmation('Senha e confirmação precisam ser iguais.');
        return;
      }

      setIsLoading(true);
      await forgotPasswordValidate(
        rememberPassword.identityNumber.replaceAll('.', '').replace('-', ''),
        rememberPassword.password,
        rememberPassword.code
      );
      setIsLoading(false);
      setPhase(2);
    } catch (e) {
      setErrorConfirmation('Código de confirmação incorreto');
      setRememberPassword({ ...rememberPassword, code: '' });
      setIsLoading(false);
    }
  };

  return (
    <EnterAnimation
      style={{ display: 'flex', flex: 1, height: '100%' }}
      direction={animationClass}
      scaled={scaledAnimation}
      onTransitionEnd={() => openRememberPasswordForm(false)}
    >
      <Loader isLoading={isLoading} />
      <S.CloseButton onClick={() => setAnimationClass('out')}>
        <img src={CloseIcon} width={24} height={24} alt="Fechar" />
      </S.CloseButton>
      <S.FormWrapper style={{ flex: 1 }}>
        <S.Container style={{ flex: 1 }}>
          <Paragraph>
            <Bold>Esqueci a senha</Bold>
          </Paragraph>
          {phase === 0 && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                sendCode();
              }}
            >
              <Flex margin="24px 0 10px 0">
                <Paragraph margin="0 0 15px 0" justify>
                  Poxa que pena que esqueceu sua senha, mas não se preocupe nós
                  iremos te ajudar.
                </Paragraph>
                <Paragraph justify>
                  Digite seu CPF ou CNPJ que você usou no seu cadastro aqui no{' '}
                  <img src={LogoUffa} alt="Uffa.com.vc" style={{ width: 35 }} />{' '}
                  e clique no botão Enviar. Nós iremos enviar um código para
                  você realizar a redefinição de senha.
                </Paragraph>
              </Flex>
              {errorSend && (
                <Flex size={1} margin="0 0 15px 0">
                  <ErrorBox
                    timing={4000}
                    onTransitionEnd={() => setErrorSend(false)}
                  >
                    {errorSend}
                  </ErrorBox>
                </Flex>
              )}
              <Flex size={1} margin="0 0 15px 0">
                <Input
                  size="fullWidth"
                  label="CPF ou CNPJ"
                  name="username"
                  required
                  value={rememberPassword.identityNumber}
                  onChange={(e) =>
                    setRememberPassword({
                      ...rememberPassword,
                      identityNumber: e.target.value,
                    })
                  }
                />
              </Flex>
              <Flex margin="15px 0 0 0">
                <Button size="fullWidth" type="submit">
                  Enviar nova senha
                </Button>
              </Flex>
            </form>
          )}
          {phase === 1 && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                confirmationCode();
              }}
            >
              <Flex margin="24px 0 10px 0">
                <Paragraph margin="0 0 15px 0" justify>
                  Nós enviamos um código para seu contato que usou para ativar
                  sua conta.
                </Paragraph>
                <Paragraph justify>
                  Digite o código e sua nova senha de acesso nos campos abaixo.
                </Paragraph>
              </Flex>
              {errorConfirmation && (
                <Flex size={1} margin="0 0 15px 0">
                  <ErrorBox
                    timing={4000}
                    onTransitionEnd={() => setErrorConfirmation(false)}
                  >
                    {errorConfirmation}
                  </ErrorBox>
                </Flex>
              )}
              {errorSend && (
                <Flex size={1} margin="0 0 15px 0">
                  <ErrorBox
                    timing={4000}
                    onTransitionEnd={() => setErrorSend(false)}
                  >
                    {errorSend}
                  </ErrorBox>
                </Flex>
              )}

              <Flex size={1} margin="0 0 15px 0">
                <Input
                  size="fullWidth"
                  label="Informe o código"
                  name="code"
                  required
                  value={rememberPassword.code}
                  onChange={(e) =>
                    setRememberPassword({
                      ...rememberPassword,
                      code: e.target.value,
                    })
                  }
                />
              </Flex>
              <Flex size={1} margin="0 0 15px 0">
                <Input
                  size="fullWidth"
                  type="password"
                  label="Senha"
                  name="password"
                  required
                  value={rememberPassword.password}
                  onChange={(e) =>
                    setRememberPassword({
                      ...rememberPassword,
                      password: e.target.value,
                    })
                  }
                />
              </Flex>
              <Flex size={1} margin="0 0 15px 0">
                <Input
                  size="fullWidth"
                  label="Confirmação da senha"
                  type="password"
                  name="confirmationPassword"
                  required
                  value={rememberPassword.confirmationPassword}
                  onChange={(e) =>
                    setRememberPassword({
                      ...rememberPassword,
                      confirmationPassword: e.target.value,
                    })
                  }
                />
              </Flex>
              <Flex margin="15px 0 0 0">
                <Button size="fullWidth" type="submit">
                  Alterar senha
                </Button>
              </Flex>
              <Flex margin="15px 0 0 0">
                <Button size="fullWidth" onClick={() => sendCode()}>
                  Reenviar código
                </Button>
              </Flex>
            </form>
          )}
          {phase === 2 && (
            <>
              <Flex margin="24px 0 10px 0">
                <Paragraph margin="0 0 15px 0" justify>
                  <b>Prontinho!</b>
                </Paragraph>
                <Paragraph margin="0 0 15px 0" justify>
                  Sua senha foi alterada com sucesso!
                </Paragraph>
                <Paragraph justify>
                  Agora você já pode realizar o seu acesso.
                </Paragraph>
              </Flex>
              <Flex margin="15px 0 0 0">
                <Button
                  size="fullWidth"
                  onClick={() => {
                    setAnimationClass('out');
                    setTimeout(() => {
                      openLoginForm(true);
                    }, 200);
                  }}
                >
                  Fazer login
                </Button>
              </Flex>
            </>
          )}
        </S.Container>
      </S.FormWrapper>
    </EnterAnimation>
  );
};

RememberPassword.defaultProps = {
  scaledAnimation: false,
};

RememberPassword.propTypes = {
  openRememberPasswordForm: PropTypes.func.isRequired,
  openLoginForm: PropTypes.func.isRequired,
  scaledAnimation: PropTypes.bool,
};

export default RememberPassword;
