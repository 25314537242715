/* eslint-disable indent */
import styled, { css } from 'styled-components';

import IconCampanha from '../../assets/icons/icon_campaigns.svg';
import IconVoucher from '../../assets/icons/icon_voucher.svg';
import IconUsedVoucher from '../../assets/icons/icon_voucher_used.svg';
import IconAvaiableVoucher from '../../assets/icons/icon_avaiable_voucher.svg';
import IconVoucherValue from '../../assets/icons/icon_voucher_value.svg';
import IconOpenIndicators from '../../assets/icons/icon_open_indicators.svg';
import IconCloseIndicators from '../../assets/icons/icon_close_indicators.svg';
import BgLineIndicators from '../../assets/icons/line_bg_indicators.svg';

export const IndicatorWrapper = styled.div`
  background: url(${BgLineIndicators}) no-repeat 30% 20%,
    linear-gradient(270deg, #ff0091 4.22%, #ff5150 24.43%, #ffb400 85.86%);
  border-radius: 0px 0px 0px 30px;
  background-size: 100%;
  position: relative;
  top: 120px;
  ${(props) =>
    props.theme.media['sm-down'](css`
     top: 90px;
    `)}
`;

export const IndicatorItem = styled.div`
  height: 85px;
  border-radius: 42.5px;
  border: 1px solid #edf2f9;
  background-color: white;
  flex: 1;
  margin: 0px 10px;
  ${(props) =>
    props.hideOn &&
    props.theme.media[props.hideOn](css`
      display: none;
    `)}
  ${(props) =>
    props.theme.media['md-down'](css`
      height: 70px;
    `)}
  ${(props) =>
    props.theme.media.md(css`
      max-width: 175px;
    `)}
 
  ${(props) =>
    props.theme.media.sm(css`
      width: 285px;
      align-self: center;
      margin: 0 auto 10px auto;
    `)}
  display: flex;
  padding: 0 24px;
  ${(props) =>
    props.theme.media.xs(css`
      width: 100%;
      :last-child {
        width: 50%;
      }
      margin: 0 auto 10px auto;
      padding: 0 15px;
      align-self: center;
    `)}
  align-items: center;
`;

export const IndicatorContainer = styled.div`
  ${(props) =>
    props.theme.media['sm-down'](css`
      transition: height 0.2s;
      height: 100px;
    ${props.isOpen &&
      css`
        height: 280px;
      `
      }
    overflow: hidden;
    grid-template-columns: repeat(2, 50%);
    display: grid;
    grid-auto-rows: minmax(100px, max-content)
    width: 100%;
    grid-template-areas:
    "a b"
    "c d"
    "e e";
    gap: 10px;
    padding: 0 24px;
    ${props.theme.media['sm-down'](css`
      padding: 15px 24px 14px 15px;
      ${IndicatorItem}:nth-child(1) {
        grid-area: a;
        justify-self: end;
      }
      ${IndicatorItem}:nth-child(2) {
        grid-area: b;
        justify-self: start;
      }
      ${IndicatorItem}:nth-child(3) {
        grid-area: c;
        justify-self: end;
      }
      ${IndicatorItem}:nth-child(4) {
        grid-area: d;
        justify-self: start;
      }
      ${IndicatorItem}:nth-child(5) {
        grid-area: e;
        justify-self: center;
      }
    `)}

  `)}

  ${(props) =>
    props.theme.media.md(css`
      height: 100px;
      width: 1000px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
    `)}

  ${(props) =>
    props.theme.media.lg(css`
      height: 126px;
      width: 1280px;
      margin: 0 auto;
      display: flex;
      align-items: center;
    `)}
`;

export const IndicatorTextWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const IndicatorValue = styled.div`
  font-size: 24px;
  ${(props) =>
    props.theme.media.md(css`
      font-size: 18px;
    `)}
  ${(props) =>
    props.theme.media.xs(css`
      font-size: 20px;
    `)}
  font-family: ${(props) => props.theme.font.nunito};
  color: ${(props) => props.theme.colors.gray};
  font-weight: 700;
  small {
    ${(props) =>
    props.theme.media.md(
      css`
          font-size: 10px;
        `
    )}
    ${(props) =>
    props.theme.media.lg(
      css`
          font-size: 14px;
        `
    )}
    ${(props) =>
    props.theme.media.xs(
      css`
          font-size: 12px;
        `
    )}
  }
`;
export const IndicatorDescription = styled.div`
  font-size: 14px;
  ${(props) =>
    props.theme.media.md(css`
      font-size: 11px;
    `)}
  ${(props) =>
    props.theme.media.xs(css`
      font-size: 11px;
    `)}
  font-family: ${(props) => props.theme.font.nunito};
  color: ${(props) => props.theme.colors.gray};
  font-weight: 700;
`;
export const IndicatorIcon = styled.div`
  width: 40px;
  height: 60px;

  ${(props) =>
    props.campanha &&
    css`
      background: url(${IconCampanha}) no-repeat 0px 10px;
    `}
  ${(props) =>
    props.voucher &&
    css`
      background: url(${IconVoucher}) no-repeat 0px 10px;
    `}
  ${(props) =>
    props.usedVoucher &&
    css`
      background: url(${IconUsedVoucher}) no-repeat 0px 10px;
    `}
  ${(props) =>
    props.avaiableVoucher &&
    css`
      background: url(${IconAvaiableVoucher}) no-repeat 0px 10px;
    `}
  ${(props) =>
    props.valueVoucher &&
    css`
      background: url(${IconVoucherValue}) no-repeat 0px 10px;
    `}

  ${(props) =>
    props.theme.media.md(css`
      width: 20px;
      height: 40px;
      background-size: 20px 30px;
      background-position: 0 5px;
    `)}

  ${(props) =>
    props.theme.media.xs(css`
      width: 20px;
      height: 40px;
      background-size: 20px 30px;
      background-position: 0 5px;
    `)}
`;

export const ToggleButton = styled.div`
  ${(props) =>
    props.theme.media['md-up'](css`
      display: none;
    `)}
  position: absolute;
  width: 28px;
  z-index: 1000;
  height: 28px;
  left: 50%;
  bottom: -18px;
  cursor: pointer;
  margin-left: -14px;
  ${(props) =>
    props.isOpen
      ? css`
          background: url(${IconOpenIndicators});
        `
      : css`
          background: url(${IconCloseIndicators});
        `}
`;
