import styled, { css } from 'styled-components';

export const ToggleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
  cursor: pointer;
`;

export const ToggleLabel = styled.div`
  color: ${props => props.theme.colors.gray};
`;

export const ToggleButton = styled.div`
  height: 20px;
  width: 20px;
  border: 2px solid ${(props) => props.theme.colors.pink};
  border-radius: 15px;
  ${props => props.active && css`background: ${props.theme.colors.pink};`}
  margin: 0 15px;
`;
