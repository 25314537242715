import React from 'react';
import { useNotification } from '../../contexts/NotificationContext';
import { useOnboardingStatus } from '../../contexts/OnboardingStatusContext';
import InfluencerCampaignService from '../../services/influencer-campaign.service';
import * as S from './style';

export default () => {
  const { showError } = useNotification();
  const { status } = useOnboardingStatus();
  const [isOpen, setIsOpen] = React.useState(false);
  const [stats, setStats] = React.useState({
    campaignsCount: 0,
    vouchersTotal: 0,
    availableForUseCount: 0,
    vouchersUsedCount: 0,
    vouchersValueSum: 0,
  });
  const toggleIndicators = () => setIsOpen(!isOpen);

  React.useEffect(() => {
    if (status && !status.notReady) {
      const loadStats = async () => {
        try {
          const data = await new InfluencerCampaignService().getStats();
          if (data) setStats(data);
        } catch (error) {
          showError('Não foi possível cerragar os indicadores das campanha');
        }
      };

      loadStats();
    }
  }, [status]);

  const formatMoney = (value) => {
    if (value === '-') {
      return value;
    }

    return value
      .toFixed(2)
      .toString()
      .replace('.', ',')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  return (
    <S.IndicatorWrapper>
      <S.IndicatorContainer isOpen={isOpen}>
        <S.IndicatorItem>
          <S.IndicatorTextWrapper>
            <S.IndicatorValue>{stats.campaignsCount}</S.IndicatorValue>
            <S.IndicatorDescription>Campanhas</S.IndicatorDescription>
          </S.IndicatorTextWrapper>
          <S.IndicatorIcon campanha />
        </S.IndicatorItem>
        <S.IndicatorItem>
          <S.IndicatorTextWrapper>
            <S.IndicatorValue>{stats.vouchersTotal}</S.IndicatorValue>
            <S.IndicatorDescription>Total de vouchers</S.IndicatorDescription>
          </S.IndicatorTextWrapper>
          <S.IndicatorIcon voucher />
        </S.IndicatorItem>
        <S.IndicatorItem>
          <S.IndicatorTextWrapper>
            <S.IndicatorValue>{stats.vouchersUsedCount}</S.IndicatorValue>
            <S.IndicatorDescription>Vouchers usados</S.IndicatorDescription>
          </S.IndicatorTextWrapper>
          <S.IndicatorIcon usedVoucher />
        </S.IndicatorItem>
        <S.IndicatorItem>
          <S.IndicatorTextWrapper>
            <S.IndicatorValue>{stats.availableForUseCount}</S.IndicatorValue>
            <S.IndicatorDescription>
              Vouchers disponíveis
            </S.IndicatorDescription>
          </S.IndicatorTextWrapper>
          <S.IndicatorIcon avaiableVoucher />
        </S.IndicatorItem>
        <S.IndicatorItem>
          <S.IndicatorTextWrapper>
            <S.IndicatorValue>
              <small>R$</small> {formatMoney(stats.vouchersValueSum)}
            </S.IndicatorValue>
            <S.IndicatorDescription>Valor em vouchers</S.IndicatorDescription>
          </S.IndicatorTextWrapper>
          <S.IndicatorIcon valueVoucher />
        </S.IndicatorItem>
      </S.IndicatorContainer>
      <S.ToggleButton isOpen={isOpen} onClick={toggleIndicators} />
    </S.IndicatorWrapper>
  );
};
