import styled, { css } from 'styled-components';
import LogoUffa from '../../assets/logo/uffa.svg';
import IconActiveDrops from '../../assets/icons/menu_active_drops.svg';
import IconMenu from '../../assets/icons/icon_menu.svg';
import IconStatusPending from '../../assets/icons/status_icon_pending.svg';
import IconStatusAnalyzing from '../../assets/icons/status_icon_analyzing.svg';
import IconStatusDone from '../../assets/icons/status_icon_done.svg';
import IconStatusRejected from '../../assets/icons/status_icon_rejected.svg';

export const MenuWrapper = styled.div`
  padding: 0 24;
  width: 100%;
  position: fixed;
  top: 0;
  background: white;
  z-index: 1000;
  ${(props) =>
    props.theme.media['sm-down'](css`
      padding: 0 24px;
      height: 90px;
      z-index: 1040;
      position: fixed;
    `)}

  ${(props) =>
    props.theme.media.md(css`
      width: 100%;
      margin: 0 auto;
    `)}

  ${(props) =>
    props.theme.media['md-up'](css`
      padding: 0 24px;
      height: 120px;
    `)}
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  ${(props) =>
    props.theme.media['sm-down'](css`
      height: 60px;
    `)}

  ${(props) =>
    props.theme.media['md-up'](css`
      height: 80px;
    `)}
`;

export const Logo = styled.img.attrs({ src: LogoUffa, alt: 'Logo' })`
  ${(props) =>
    props.theme.media['sm-down'](css`
      width: 60px;
    `)}
  ${(props) =>
    props.theme.media.md(css`
      width: 80px;
    `)}

  ${(props) =>
    props.theme.media.lg(css`
      width: 100%;
    `)}
`;

export const Menu = styled.div`
  ${(props) =>
    props.theme.media['sm-down'](css`
      width: 100%;
      margin: 0 auto;
      height: 60px;
      justify-content: space-between;
      display: flex;
      align-items: center;
    `)}

  ${(props) =>
    props.theme.media.md(css`
      height: 80px;
      width: 1000px;
      margin: 0 auto;
      display: grid;
      grid-template-rows: 960px;
      grid-template-columns: 80px 676px 204px;
    `)}

  ${(props) =>
    props.theme.media.lg(css`
      height: 80px;
      width: 1280px;
      margin: 0 auto;
      display: grid;
      grid-template-rows: 1280px;
      grid-template-columns: 120px 866px 294px;
    `)}
`;

export const MenuBorder = styled.div`
  height: 4px;
  width: 100%;
  z-index: 20;
  position: fixed;
  top: 120px;
  background: linear-gradient(
    81.12deg,
    #ff0091 11.88%,
    #ff5150 40.62%,
    #ffb400 78.32%
  );
  ${(props) =>
    props.theme.media['sm-down'](css`
      top: 90px;
    `)}
`;

export const MenuActiveBorder = styled.div`
  height: 4px;
  background-color: ${(props) => props.theme.colors.yellow};
  width: 100%;
  border-radius: 2px;
  position: absolute;
`;

export const MenuActiveDrops = styled.img.attrs({
  src: IconActiveDrops,
  alt: '',
})`
  position: absolute;
  top: 13px;
  right: -18px;
`;

export const MenuButton = styled.img.attrs({
  src: IconMenu,
  alt: 'Abrir Menu',
})`
  width: 32px;
  height: 32px;
`;

export const MenuItem = styled.div`
  font-family: ${(props) => props.theme.font.nunito};
  color: ${(props) => props.theme.colors.gray};
  font-size: 16px;
  ${(props) => props.theme.media.md(css`font-size: 14px;`)}
  position: relative;
  padding: 20px 0;
  margin: 0 20px;
  ${MenuActiveBorder}, ${MenuActiveDrops} {
    display: none;
  }
  :hover {
    color: ${(props) => props.theme.colors.yellow};
    cursor: pointer;
  }
  ${(props) =>
    props.active &&
    css`
      color: ${props.theme.colors.yellow};
      font-weight: 700;
      ${MenuActiveBorder}, ${MenuActiveDrops} {
        display: block;
      }
    `}
`;

export const Logout = styled.div`
  margin-left: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;

export const MenuList = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  a {
    border: 0;
    text-decoration: none;
  }
  ${(props) =>
    props.theme.media['sm-down'](css`
    position: fixed;
    opacity: 0;
    top: -500px;
    z-index: 1030;
    transition: top 0.2s, opacity 0.2s;
    background: #FF0091;
    padding: 0;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    left: 0;
    flex-direction: column;
    width: 100%;
    ${MenuItem} {
      text-align: center;
      color: white !important;
      width: 100%;
      ${MenuItem} {
        text-align: center;
        color: white !important;
        width: 100%;
      }
      ${MenuActiveBorder}, ${MenuActiveDrops} {
        display: none;
      }
    }
    ${props.isOpenMenu && css`
        opacity: 1;
        top: 94px;
      `} 
  `)}
  ${(props) =>
    props.theme.media['md-up'](css`
    height: 80px;
    ${MenuItem}: {
      color: ${props.theme.colors.gray};
    }
  `)}
`;

export const MinhaContaWrapper = styled.div`
  display: flex;
  font-size: 14px;
  font-family: ${(props) => props.theme.font.nunito};
  height: 80px;
  align-items: center;
`;

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
`;

// export const Avatar = styled.div`
//  background: url(${(props) => props.image || CatLargeAvatar}) no-repeat;
//  width: 40px;
//  height: 40px;
// `;

export const StatusWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 15px;
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  ${(props) =>
    props.url &&
    css`
      cursor: pointer;
    `}
`;

export const StatusIcon = styled.div`
  width: 30px;
  height: 30px;
  ${(props) =>
    props.pending &&
    css`
      background: url(${IconStatusPending}) no-repeat;
    `}
  ${(props) =>
    props.analyzing &&
    css`
      background: url(${IconStatusAnalyzing}) no-repeat;
    `}
  ${(props) =>
    props.done &&
    css`
      background: url(${IconStatusDone}) no-repeat;
    `}
  ${(props) =>
    props.rejected &&
    css`
      background: url(${IconStatusRejected}) no-repeat;
    `}
`;

export const LinkMinhaConta = styled.span`
  margin-left: 5px;
  margin-right: 15px;
  text-decoration: none;
  font-weight: 700;
  color: ${(props) => props.theme.colors.gray};
  :hover {
    color: ${(props) => props.theme.colors.yellow};
    cursor: pointer;
  }
`;
