import styled, { css } from 'styled-components';
import px2vw from '../../utils/px2vw';
import RodapeDesktop from '../../assets/icons/rodape_web.svg';
import RodapeMobile from '../../assets/icons/rodape_mobile.svg';

export const ChildrenWrapper = styled.div`
  margin-top: 160px;
  ${(props) =>
    props.theme.media['sm-down'](css`
      margin-top: 120px;
    `)}
`;

export const Container = styled.div`
  width: 1280px;
  margin: 0 auto;
  ${(props) =>
    props.theme.media.md(css`
      width: 1024px;
    `)}
  ${(props) =>
    props.theme.media.sm(css`
      width: 768px;
    `)}
  ${(props) =>
    props.theme.media.xs(css`
      width: 90%;
      &.filter {
        padding: 0 10px 0 10px;
        width: 100%;
      }
    `)}
`;

export const Bottom = styled.div`
  width: 100%;
  height: ${px2vw(153, 1440)};
  background: url(${RodapeDesktop}) no-repeat;
  background-size: 100%;
  ${(props) =>
    props.theme.media['sm-down'](css`
      height: ${px2vw(66, 414)};
      background: url(${RodapeMobile}) no-repeat;
      background-size: 100%;
    `)}
`;
