import React from 'react';
import { WaveDesktop, WaveMobile } from './wave';
import * as S from './style';
import { ReactComponent as LogoMobile } from '../../../assets/logo/uffa_white.svg';

const BackgroundDesktop = () => (
  <S.BackgroundDesktop>
    <S.LinearBackground>
      <WaveDesktop />
      <S.Woman>
        <S.WomanImg />
      </S.Woman>
    </S.LinearBackground>
  </S.BackgroundDesktop>
);

const BackgroundMobile = () => (
  <S.BackgroundMobile>
    <S.LinearBackground>
      <S.LogoMobileWrapper>
        <LogoMobile width={80} />
      </S.LogoMobileWrapper>
      <S.Woman>
        <S.WomanImg />
      </S.Woman>
      <WaveMobile />
    </S.LinearBackground>
  </S.BackgroundMobile>
);

export { LoginContentMobile, LoginContentDesktop } from './content';
export { default as LoginForm } from './login-form';
export { default as RegisterForm } from './register-form';
export { default as RememberPasswordForm } from './remember-password';
export { BackgroundDesktop, BackgroundMobile };
