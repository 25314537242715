import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as LogoDesktop } from '../../../../assets/logo/uffa.svg';
import { ReactComponent as DollarsLarge } from '../../../../assets/icons/dollars_large.svg';
import { ReactComponent as Dollars } from '../../../../assets/icons/dollars.svg';

import px2vw from '../../../../utils/px2vw';
import { Flex, FlexWrapper } from '../../../../components/layout';
import { Bold, Paragraph, Title } from '../../../../components/text';
import { Button, SocialButton } from '../../../../components/button';

import * as S from './style';

export const LoginContentDesktop = ({
  openLoginForm,
  openRegisterForm,
  openRememberPasswordForm,
  anyFormIsOpen,
  formRender: FormRender,
  loginFacebook,
  loginGoogle,
}) => (
  <S.WrapperDesktop>
    <Flex style={{ marginBottom: 40 }}>
      <LogoDesktop width={px2vw(120)} />
    </Flex>

    <>
      <Flex size={1}>
        {anyFormIsOpen && (
          <FormRender
            openLoginForm={openLoginForm}
            openRegisterForm={openRegisterForm}
            openRememberPasswordForm={openRememberPasswordForm}
            loginFacebook={loginFacebook}
            loginGoogle={loginGoogle}
          />
        )}
        {!anyFormIsOpen && (
          <>
            <Title large dark>
              Hey Influencer!
            </Title>
            <Paragraph dark extralarge>
              Cadastre{' '}
              <Bold dark extralarge>
                agora
              </Bold>{' '}
              e vem <br /> ganhar dinheiro <br /> com a gente.
              <DollarsLarge
                style={{
                  position: 'absolute',
                  zIndex: 1,
                  marginTop: -35,
                  marginLeft: 50,
                  width: px2vw(120, 1920),
                }}
              />
            </Paragraph>

            <Flex style={{ marginTop: 45 }}>
              <Button size="fullWidth" onClick={openRegisterForm}>
                Cadastrar
              </Button>
            </Flex>
            <Flex style={{ marginTop: 30 }}>
              <Title medium>Já tenho cadastro</Title>
              <FlexWrapper
                style={{
                  width: 115,
                  justifyContent: 'space-between',
                  margin: '15px 0px 30px 0px',
                }}
              >
                {/* <SocialButton mini type="google" onClick={loginGoogle} /> */}
                <SocialButton mini type="facebook" onClick={loginFacebook} />
              </FlexWrapper>
              <Button size="fullWidth" onClick={() => openLoginForm(true)}>
                Entrar com usuário e senha
              </Button>
            </Flex>
          </>
        )}
      </Flex>
    </>
  </S.WrapperDesktop>
);

LoginContentDesktop.propTypes = {
  openLoginForm: PropTypes.func.isRequired,
  openRegisterForm: PropTypes.func.isRequired,
  openRememberPasswordForm: PropTypes.func.isRequired,
  anyFormIsOpen: PropTypes.any.isRequired,
  formRender: PropTypes.any.isRequired,
  loginFacebook: PropTypes.func.isRequired,
  loginGoogle: PropTypes.func.isRequired,
};

export const LoginContentMobile = ({
  openLoginForm,
  openRegisterForm,
  openRememberPasswordForm,
  anyFormIsOpen,
  formRender: FormRender,
  loginFacebook,
  loginGoogle,
}) => (
  <>
    {anyFormIsOpen && (
      <div
        style={{
          position: 'fixed',
          zIndex: 100,
          width: '100%',
          height: '100%',
        }}
      >
        <FormRender
          loginFacebook={loginFacebook}
          openLoginForm={openLoginForm}
          openRegisterForm={openRegisterForm}
          openRememberPasswordForm={openRememberPasswordForm}
          loginGoogle={loginGoogle}
          scaledAnimation
        />
      </div>
    )}

    <Flex padding="0 0 60px 0">
      <FlexWrapper column>
        <Flex margin="0 66px">
          <Flex margin="0 0 15px 0">
            <Title center dark>
              Hey Influencer!
            </Title>
            <div style={{ textAlign: 'justify' }}>
              <Paragraph large dark>
                Cadastre{' '}
                <Bold dark extralarge>
                  agora
                </Bold>{' '}
                e vem ganhar dinheiro com a gente.
                <Dollars
                  style={{
                    display: 'inline',
                    zIndex: 1,
                    marginTop: 0,
                    paddingLeft: 10,
                    width: px2vw(50, 425),
                  }}
                />
              </Paragraph>
            </div>

            <Flex margin="15px 0 0 0 ">
              <Button size="fullWidth" onClick={() => openRegisterForm(true)}>
                Cadastrar
              </Button>
            </Flex>
          </Flex>
          <Title center>Já tenho cadastro</Title>
          <FlexWrapper
            style={{
              width: '50%',
              justifyContent: 'space-around',
              margin: '15px auto 24px auto',
              maxWidth: '250px',
            }}
          >
            {/* <SocialButton mini type="google" onClick={loginGoogle} /> */}
            <SocialButton mini type="facebook" onClick={loginFacebook} />
          </FlexWrapper>
          <Button size="fullWidth" onClick={() => openLoginForm(true)}>
            Entrar com e-mail e senha
          </Button>
        </Flex>
      </FlexWrapper>
    </Flex>
  </>
);

LoginContentMobile.propTypes = {
  openLoginForm: PropTypes.func.isRequired,
  openRegisterForm: PropTypes.func.isRequired,
  openRememberPasswordForm: PropTypes.func.isRequired,
  anyFormIsOpen: PropTypes.any.isRequired,
  formRender: PropTypes.any.isRequired,
  loginFacebook: PropTypes.func.isRequired,
  loginGoogle: PropTypes.func.isRequired,
};
