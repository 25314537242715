import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import { isLoggedIn } from '../utils/auth';
import { AppWrapper } from '../components/app-container';
import Term from '../components/term';
import Loader from '../components/loader';
import OnboardingStatusProvider, {
  useOnboardingStatus,
} from '../contexts/OnboardingStatusContext';

import Login from '../pages/login';
import Privacy from '../pages/security/privacy';
import ExcludeData from '../pages/security/exclude-data';
// import InfluencerRegisterService from '../services/influencer-register.service';

const App = React.lazy(() => import('../pages/app'));
const ComoFunciona = React.lazy(() => import('../pages/how-to-work'));
const MyAccount = React.lazy(() => import('../pages/my-account'));
const Downloads = React.lazy(() => import('../pages/downloads'));
const RegisterUffaencer = React.lazy(() =>
  import('../pages/register-uffaencer')
);
const RegisterUffaencerSuccess = React.lazy(() =>
  import('../pages/register-uffaencer-success')
);
const RegisterUffaencerRefused = React.lazy(() =>
  import('../pages/register-uffaencer-refused')
);

const Campaign = React.lazy(() => import('../pages/campaign'));
const CampaignDetails = React.lazy(() => import('../pages/campaign-details'));
const MyResults = React.lazy(() => import('../pages/my-results'));
const Ranking = React.lazy(() => import('../pages/ranking'));

const PrivateRoute = ({
  isAuth,
  exact,
  path,
  component: Component,
  redirectPath,
}) => (
  <Route
    exact={exact}
    path={path}
    render={(props) =>
      isAuth() ? <Component {...props} /> : <Redirect to={redirectPath} />
    }
  />
);

PrivateRoute.defaultProps = {
  exact: false,
};

PrivateRoute.propTypes = {
  isAuth: PropTypes.func.isRequired,
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
  redirectPath: PropTypes.string.isRequired,
  //  setProfileImage: PropTypes.func,
};

// const IRS = new InfluencerRegisterService();

const Routes = () => {
  const onboardingStatus = useOnboardingStatus();
  const [isUffaencer, setIsUffaencer] = React.useState(false);
  /* const [profileImage, setProfileImage] = React.useState();

  const getProfileImage = async () => {
    try {
      const data = await IRS.getProfileImage();
      setProfileImage(data);
    } catch (e) {
    }
  };

  React.useEffect(() => {
    getProfileImage();
  }, []); */

  React.useEffect(() => {
    if (!onboardingStatus?.status?.notReady) {
      setIsUffaencer(
        ['Approved', 'Active'].includes(onboardingStatus?.status?.status)
      );
    } else {
      onboardingStatus.getStatus();
    }
  }, []);
  // Alterar tipo do termo para Users Term
  return (
    <Router>
      <Switch>
        <Route exact path="/security/privacy">
          <Privacy />
        </Route>
        <Route exact path="/security/exclude-data">
          <ExcludeData />
        </Route>
        <Route exact path="/login">
          <Login />
        </Route>
        <AppWrapper>
          {isLoggedIn() ? <Term termType="use" /> : null}
          <Suspense fallback={<Loader isLoading fixed />}>
            <Switch>
              <PrivateRoute
                isAuth={() => isLoggedIn()}
                path="/"
                exact
                component={App}
                redirectPath="/login"
              />

              <PrivateRoute
                isAuth={() => isLoggedIn()}
                path="/meus-resultados"
                exact
                component={MyResults}
                redirectPath="/login"
              />
              <PrivateRoute
                isAuth={() => isLoggedIn()}
                path="/campanhas"
                exact
                component={Campaign}
                redirectPath="/login"
              />
              <PrivateRoute
                isAuth={() => isLoggedIn()}
                path="/campanhas/:id"
                exact
                component={CampaignDetails}
                redirectPath="/login"
              />
              <PrivateRoute
                isAuth={() => isLoggedIn()}
                path="/como-funciona"
                exact
                component={ComoFunciona}
                redirectPath="/login"
              />
              <PrivateRoute
                isAuth={() => isLoggedIn()}
                path="/downloads"
                exact
                component={Downloads}
                redirectPath="/login"
              />
              <PrivateRoute
                isAuth={() => isLoggedIn()}
                path="/minha-conta"
                exact
                component={MyAccount}
                redirectPath="/login"
              />
              {!isUffaencer && (
                <Switch>
                  <PrivateRoute
                    isAuth={() => isLoggedIn()}
                    path="/cadastro-uffaencer"
                    exact
                    component={RegisterUffaencer}
                    redirectPath="/login"
                  />
                  <PrivateRoute
                    isAuth={() => isLoggedIn()}
                    path="/cadastro-uffaencer/sucesso"
                    exact
                    component={RegisterUffaencerSuccess}
                    redirectPath="/login"
                  />
                  <PrivateRoute
                    isAuth={() => isLoggedIn()}
                    path="/cadastro-uffaencer/reprovado"
                    exact
                    component={RegisterUffaencerRefused}
                    redirectPath="/login"
                  />
                  <PrivateRoute
                    isAuth={() => isLoggedIn()}
                    path="/ranking"
                    exact
                    component={Ranking}
                    redirectPath="/login"
                  />
                  <Redirect to="/login" />
                </Switch>
              )}
              <Redirect to="/login" />
            </Switch>
          </Suspense>
        </AppWrapper>
      </Switch>
    </Router>
  );
};

export default () => (
  <OnboardingStatusProvider>
    <Routes />
  </OnboardingStatusProvider>
);
