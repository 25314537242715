import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useGoogleLogin } from 'react-use-googlelogin';

const createContext = () => {
  const ctx = React.createContext(undefined);

  const useCtx = () => {
    const contextValue = useContext(ctx);

    if (contextValue === undefined)
      throw new Error('useCtx must be inside a Provider with a value');

    return contextValue;
  };
  const ret = [useCtx, ctx.Provider];
  return ret;
};

const [useGoogleAuth, AuthProvider] = createContext();

export const GoogleAuthProvider = ({ children }) => {
  const hookData = useGoogleLogin({
    clientId:
      '569780643354-cmp86g47hnt79qm1cjqpqpen7oom71ha.apps.googleusercontent.com',
  });

  return <AuthProvider value={hookData}>{children}</AuthProvider>;
};

GoogleAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export { useGoogleAuth };
