import React from 'react';
import PropTypes from 'prop-types';
import ReactDom from 'react-dom';
import {
  ModalBackdrop,
  ModalWrapper,
  ModalCloseButton,
  ModalContent,
} from './style';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

const modalRoot = document.getElementById('modal-root');

const Modal = ({
  isOpen,
  children,
  onClose,
  large,
  disableClose,
  className,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [fade, setFade] = React.useState('');

  const onEscKeyDown = (e) => {
    if (e.key !== 'Escape') return;
    if (!disableClose) setFade('out');
  };

  React.useEffect(() => {
    window.addEventListener('keydown', onEscKeyDown, false);
    setTimeout(() => setFade('in'), 0);

    return () => {
      window.removeEventListener('keydown', onEscKeyDown, false);
    };
  }, []);

  React.useEffect(() => {
    setIsModalOpen(isOpen);
    if (isModalOpen && !isOpen) {
      setFade('out');
    } else if (isOpen) {
      setFade('in');
    }
  }, [isOpen]);

  const transitionEnded = () => {
    if (fade === 'out') {
      if (!disableClose && onClose) onClose();
    }
  };

  return ReactDom.createPortal(
    isModalOpen ? (
      <ModalBackdrop
        role="dialog"
        className={fade}
        isModalOpen={isModalOpen}
        onTransitionEnd={transitionEnded}
        style={{ zIndex: 1400 }}
      >
        <ModalWrapper
          className={`modal-wrapper ${className}`}
          large={large}
          isModalOpen={isModalOpen}
        >
          {disableClose ? null : (
            <ModalCloseButton onClick={() => setFade('out')}>
              <CloseIcon width={24} height={24} />
            </ModalCloseButton>
          )}
          <ModalContent className={className}>{children}</ModalContent>
        </ModalWrapper>
      </ModalBackdrop>
    ) : null,
    modalRoot
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  large: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  disableClose: PropTypes.bool,
  className: PropTypes.string,
};

Modal.defaultProps = {
  onClose: null,
  className: null,
  disableClose: false,
  large: false,
};

export default Modal;
