import styled, { css } from 'styled-components';
import px2vw from '../../../../utils/px2vw';

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  border-radius: 15px;
  box-shadow: 0 0px 20px #00000055;
  padding: 30px;
  background-color: white;
  ${props => props.theme.media['sm-down'](css`
    height: 100%;
    overflow-y: auto;
  `)}

`;

export const OrText = styled.p`
  position: relative;
  top: -13px;
  text-align: center;
  background: #fff;
  width: 40px;
  margin: 0 auto;
  font-family: ${props => props.theme.font.nunito};
  font-size: ${props => px2vw(props.theme.fsize.paragraph.medium, 425)};
`;


export const CloseButton = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  padding: 10px;
  width: 44px;
  height: 44px;
  border-radius: 22.5px;
  cursor: pointer;
  z-index: 100;
  :hover {
    background-color: #00000022;
  }
`;