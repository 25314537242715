import React from 'react';
import PropTypes from 'prop-types';
import { Title, Paragraph } from '../text';
import { Button } from '../button';
import Loader from '../loader';
import TermService from '../../services/term.service';
import { useNotification } from '../../contexts/NotificationContext';
import Modal from '../modal';
import * as S from './style';

const mockTerm = {
  termsId: '5E0EDA02-C379-472C-919F-E3A60061BBD0',
  termType: 'Use',
  title: 'Termo de uso do Uffa',
  description:
    'Existem muitas variações disponíveis de passagens de Lorem Ipsum, mas a maioria sofreu algum tipo de alteração, seja por inserção de passagens com humor, ou palavras aleatórias que não parecem nem um pouco convincentes. Se você pretende usar uma passagem de Lorem Ipsum, precisa ter certeza de que não há algo embaraçoso escrito escondido no meio do texto. Todos os geradores de Lorem Ipsum na internet tendem a repetir pedaços predefinidos conforme necessário, fazendo deste o primeiro gerador de Lorem Ipsum autêntico da internet. Ele usa um dicionário com mais de 200 palavras em Latim combinado com um punhado de modelos de estrutura de frases para gerar um Lorem Ipsum com aparência razoável, livre de repetições, inserções de humor, palavras não características, etc.',
  status: 'None',
  version: '1.0.0',
  acceptAt: '2021-08-17T06:50:02.736Z',
};

const TS = new TermService();

const Term = ({ termType, isOpen, onAccept }) => {
  const [term, setTerm] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isTermsAccepted, setTermsAccepted] = React.useState(!isOpen);
  const notification = useNotification();

  React.useEffect(() => {
    const fetchTerm = async () => {
      setIsLoading(true);
      let data;
      try {
        data = await TS.getUserTerm(termType);
      } catch (e) {
        data = mockTerm;
      }
      setTerm(data);
      if (data?.acceptAt !== null) {
        //
        setTermsAccepted(true);
      } else {
        setTermsAccepted(false);
      }
      setIsLoading(false);
    };
    fetchTerm();
  }, []);

  const acceptTerm = async () => {
    setIsLoading(true);
    try {
      await TS.postUserTerm({ termsId: term.termsId });
      setTermsAccepted(true);
      onAccept();
      notification.showSuccess('Termo aceito com sucesso!');
    } catch (e) {
      if (e.type !== 'ALREADY_EXISTS') {
        setTermsAccepted(false);
        notification.showError(e.message);
        return;
      }
      notification.showSuccess('Termo aceito com sucesso!');
      setTermsAccepted(true);
      onAccept();
    } finally {
      setIsLoading(false);
    }
  };

  const declineTerm = () => {
    notification.showError('Você deve aceitar os termos para continuar!');
    setTermsAccepted(false);
  };

  return (
    <>
      {!isTermsAccepted ? (
        <Modal
          large
          isOpen={!isTermsAccepted}
          disableClose
          className="terms"
          style={{ zIndex: 1400 }}
        >
          <Loader fixed isLoading={isLoading} />
          <S.TitleWrapper>
            <Title gray micro>
              {term?.title}
            </Title>
          </S.TitleWrapper>
          <S.TermWrapper>
            <Paragraph>
              <>{term?.description}</>
            </Paragraph>
          </S.TermWrapper>
          <S.ButtonWrapper>
            <Button onClick={() => declineTerm()} secondary>
              Recusar
            </Button>
            <Button onClick={() => acceptTerm()}>Aceitar</Button>
          </S.ButtonWrapper>
        </Modal>
      ) : null}
    </>
  );
};

Term.propTypes = {
  termType: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onAccept: PropTypes.func,
};

Term.defaultProps = {
  isOpen: false,
  onAccept: () => null,
};

export default Term;
