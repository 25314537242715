/* eslint-disable */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import cloneDeep from 'lodash.clonedeep';
import { withRouter } from 'react-router-dom';
import Term from '../term';
import { useNotification } from '../../contexts/NotificationContext';
import { useOnboardingStatus } from '../../contexts/OnboardingStatusContext';
import {
  postUser,
  checkUser,
  sendConfirmationCodeToUser,
} from '../../services/user';

import { validarCPF, validarCNPJ } from '../../utils';
import * as S from './style';
import CloseIcon from '../../assets/icons/close.svg';
import SendMessageIcon from '../../assets/icons/send_message.svg';

import { MessageFlow } from './config';
import { EnterAnimation } from '../animation';
import MessageBox from './components/message_box';

let formData = {};

const Chat = ({
  openRegisterForm,
  scaledAnimation,
  openRememberPasswordForm,
  history,
  loginFacebook,
  loginGoogle,
}) => {
  const [isTermsAccepted, setTermsAccepted] = React.useState(true);
  const [animationClass, setAnimationClass] = React.useState('in');
  const notification = useNotification();
  const onboardingStatus = useOnboardingStatus();
  const [chatData, setChatData] = React.useState({
    activeMessage: MessageFlow.INICIO,
  });

  const [messages, setMessages] = React.useState([]);
  const [inputText, setInputText] = React.useState('');

  const setFormValue = (field, value) => {
    formData = { ...formData, [field]: value };
  };

  const setMessageActive = (activeMessage) => {
    setChatData({ activeMessage });
  };

  const parseVariableToText = (text, variables) => {
    variables.map((variable) => {
      const regexp = new RegExp(`%${variable}%`, 'gi');
      text = text.replace(regexp, formData[variable]);
    });
    return text;
  };

  const pushMessage = (messageFlow, textIndex = 0, isUffa = true) => {
    const message = cloneDeep(messageFlow);
    if (isUffa) {
      if (!message.customMessage) {
        setMessageActive(message);
      }
      if (message.variables && message.variables.length) {
        const messageText = parseVariableToText(
          message.text[textIndex],
          message.variables
        );
        message.text[textIndex] = messageText;
      }
    }
    setMessages((messageList) => [
      {
        ...message,
        text: message.text[textIndex],
        id: messageList.length,
        timestamp: new Date(),
        uffa: isUffa,
        typingDelay:
          isUffa &&
          Math.floor(
            Math.random() * (message.delay[1] - message.delay[0] + 1) +
            message.delay[0]
          ),
      },
      ...messageList,
    ]);
  };

  const pushClientMessage = (message, textIndex = 0) => {
    pushMessage(message, textIndex, false);
  };

  const setFlowBindings = (chatData) => {
    MessageFlow.INICIO.callback = () => pushMessage(MessageFlow.TIPO_CADASTRO);

    MessageFlow.TIPO_CADASTRO.optionOnClick = (value, label) => {
      pushClientMessage({
        id: uuidv4(),
        text: [label],
      });
      setTimeout(() => {
        pushMessage(value);
      }, 600);
    };

    MessageFlow.REDE_SOCIAL_ERROR.callback = () =>
      pushMessage(MessageFlow.ESCOLHA_A_REDE_SOCIAL, 1);

    MessageFlow.ESCOLHA_A_REDE_SOCIAL.optionOnClick = (value, label) => {
      pushClientMessage({
        id: uuidv4(),
        text: [label],
      });
      if (value === 'Facebook') {
        loginFacebook();
      } else {
        loginGoogle();
      }
    };

    MessageFlow.INICIO_CONVENCIONAL.callback = () =>
      pushMessage(MessageFlow.INFORME_SEU_NOME);

    MessageFlow.NOME_ALTERADO.callback = () => {
      pushMessage(chatData.activeMessage, 1);
    };

    MessageFlow.INFORME_SEU_NOME.onSendMessage = (text) => {
      setFormValue('name', text);
      pushClientMessage({
        id: uuidv4(),
        text: [text],
        editableBallon: {
          ...MessageFlow.INFORME_SEU_NOME.inputProps,
        },
        onEditMessage: (text) => {
          setFormValue('name', text);
          pushMessage(MessageFlow.NOME_ALTERADO);
          return text;
        },
      });
      setTimeout(() => {
        pushMessage(MessageFlow.BEMVINDO);
      }, 600);
    };

    MessageFlow.BEMVINDO.callback = () =>
      pushMessage(MessageFlow.INFORME_SEU_CPFCNPJ);

    MessageFlow.CPFCNPJ_INVALIDO.callback = () =>
      pushMessage(chatData.activeMessage, 1);

    MessageFlow.CPFCNPJ_ALTERADO.callback = () => {
      const isCPF = validarCPF(formData.cpfCnpj);
      pushMessage(
        isCPF
          ? MessageFlow.INFORME_SEU_TELEFONE
          : MessageFlow.INFORME_NOME_FANTASIA
      );
    };

    MessageFlow.NOME_FANTASIA_ALTERADO.callback = () => {
      pushMessage(chatData.activeMessage, 1);
    };

    const checkRegisteredUser = async (param, type, failedMessage) => {
      try {
        if (type === 'identityNumber') param = param.replace(/\D/g, '');
        await checkUser(param, type);
        setTimeout(() => {
          pushMessage(failedMessage, 1);
        }, 600);
        return true;
      } catch (e) {
        const {
          response: {
            data: { code },
          },
        } = e;
        if (code === 404) return false;
        else {
          setTimeout(() => {
            pushMessage(failedMessage, 1);
          }, 600);
          return true;
        }
      }
    };

    const checkCPFCNPJ = async (text) => {
      const isCPF = validarCPF(text);
      const isCNPJ = validarCNPJ(text);
      if (!isCPF && !isCNPJ) {
        pushClientMessage({
          id: uuidv4(),
          text: [text],
        });
        setTimeout(() => {
          pushMessage(MessageFlow.CPFCNPJ_INVALIDO);
        }, 600);
      } else {
        const _text = isCPF
          ? MessageFlow.INFORME_SEU_CPFCNPJ.maskCpf(text)
          : MessageFlow.INFORME_SEU_CPFCNPJ.maskCnpj(text);

        pushClientMessage({
          id: uuidv4(),
          text: [_text],
          editableBallon: {
            ...MessageFlow.INFORME_SEU_CPFCNPJ.inputProps,
          },
          onEditMessage: async (text) => {
            const isCPF = validarCPF(text);
            const isCNPJ = validarCNPJ(text);
            if (!isCPF && !isCNPJ) {
              pushMessage(MessageFlow.CPFCNPJ_INVALIDO);
              return formData.cpfCnpj;
            } else {
              const _text = isCPF
                ? MessageFlow.INFORME_SEU_CPFCNPJ.maskCpf(text)
                : MessageFlow.INFORME_SEU_CPFCNPJ.maskCnpj(text);
              const hasUser = await checkRegisteredUser(
                text,
                'identityNumber',
                MessageFlow.CPFCNPJ_CADASTRADO
              );
              if (!hasUser) {
                setFormValue('cpfCnpj', text);
                pushMessage(MessageFlow.CPFCNPJ_ALTERADO);
                return _text;
              }
            }
          },
        });
        const hasUser = await checkRegisteredUser(
          text,
          'identityNumber',
          MessageFlow.CPFCNPJ_CADASTRADO
        );
        if (!hasUser) {
          setFormValue('cpfCnpj', text);

          setTimeout(() => {
            pushMessage(
              isCPF
                ? MessageFlow.INFORME_SEU_TELEFONE
                : MessageFlow.INFORME_NOME_FANTASIA
            );
          }, 600);
        }
      }
    };

    MessageFlow.INFORME_SEU_CPFCNPJ.onSendMessage = checkCPFCNPJ;
    MessageFlow.CPFCNPJ_CADASTRADO.onSendMessage = checkCPFCNPJ;

    MessageFlow.CPFCNPJ_CADASTRADO.optionOnClick = () => {
      setAnimationClass('out');
      setTimeout(() => openRememberPasswordForm(true), 200);
    };

    MessageFlow.INFORME_NOME_FANTASIA.onSendMessage = (text) => {
      setFormValue('companyName', text);
      pushClientMessage({
        id: uuidv4(),
        text: [text],
        editableBallon: true,
        onEditMessage: (text) => {
          setFormValue('companyName', text);
          pushMessage(MessageFlow.NOME_FANTASIA_ALTERADO);
          return text;
        },
      });
      setTimeout(() => {
        pushMessage(MessageFlow.INFORME_SEU_TELEFONE);
      }, 600);
    };

    const checkCellphone = async (text) => {
      const _text = MessageFlow.INFORME_SEU_TELEFONE.mask(text);

      pushClientMessage({
        id: uuidv4(),
        text: [_text],
        editableBallon: {
          ...MessageFlow.INFORME_SEU_TELEFONE.inputProps,
        },
        onEditMessage: async (text) => {
          const _text = MessageFlow.INFORME_SEU_TELEFONE.mask(text);
          const hasUser = await checkRegisteredUser(
            text,
            'cellphone',
            MessageFlow.TELEFONE_CADASTRADO
          );
          if (!hasUser) {
            setFormValue('cellphone', text);
            pushMessage(MessageFlow.TELEFONE_ALTERADO);
          } else {
            pushMessage(MessageFlow.TELEFONE_CADASTRADO);
          }
          return _text;
        },
      });
      const hasUser = await checkRegisteredUser(
        text,
        'cellphone',
        MessageFlow.TELEFONE_CADASTRADO
      );
      if (!hasUser) {
        setFormValue('cellphone', text);
        setTimeout(() => {
          pushMessage(MessageFlow.INFORME_SEU_EMAIL);
        }, 600);
      }
    };

    MessageFlow.TELEFONE_ALTERADO.callback = () => {
      pushMessage(MessageFlow.INFORME_SEU_EMAIL);
    };

    MessageFlow.EMAIL_ALTERADO.callback = () => {
      pushMessage(MessageFlow.REDE_SOCIAL_UTILIZADA);
    };

    MessageFlow.INFORME_SEU_TELEFONE.onSendMessage = checkCellphone;
    MessageFlow.TELEFONE_CADASTRADO.onSendMessage = checkCellphone;

    MessageFlow.TELEFONE_CADASTRADO.optionOnClick = () => {
      setAnimationClass('out');
      setTimeout(() => openRememberPasswordForm(true), 200);
    };

    const checkEmail = async (text) => {
      pushClientMessage({
        id: uuidv4(),
        text: [text],
        editableBallon: {
          ...MessageFlow.INFORME_SEU_EMAIL.inputProps,
        },
        onEditMessage: async (text) => {
          const hasUser = await checkRegisteredUser(
            text,
            'email',
            MessageFlow.CPFCNPJ_CADASTRADO
          );
          if (!hasUser) {
            setFormValue('email', text);
            pushMessage(MessageFlow.EMAIL_ALTERADO);
          } else {
            pushMessage(MessageFlow.EMAIL_CADASTRADO);
          }
          return text;
        },
      });
      const hasUser = await checkRegisteredUser(
        text,
        'email',
        MessageFlow.EMAIL_CADASTRADO
      );
      if (!hasUser) {
        setFormValue('email', text);
        setTimeout(() => {
          pushMessage(MessageFlow.REDE_SOCIAL_UTILIZADA);
        }, 600);
      }
    };

    MessageFlow.INFORME_SEU_EMAIL.onSendMessage = checkEmail;
    MessageFlow.EMAIL_CADASTRADO.onSendMessage = checkEmail;

    MessageFlow.REDE_SOCIAL_UTILIZADA.optionOnClick = (value, label) => {
      pushClientMessage({
        id: uuidv4(),
        text: [label],
      });
      setFormValue('socialPlatform', value);
      setTimeout(() => {
        pushMessage(MessageFlow.REDE_SOCIAL_NOME_USUARIO, 0, true);
      }, 600);
    };

    MessageFlow.REDE_SOCIAL_NOME_USUARIO.onSendMessage = (text) => {
      setFormValue('socialUser', text);
      pushClientMessage({
        id: uuidv4(),
        text: [text],
        editableBallon: true,
        onEditMessage: (text) => {
          setFormValue('socialUser', text);
          pushMessage(MessageFlow.REDE_SOCIAL_NOME_USUARIO_ALTERADO);
          return text;
        },
      });
      setTimeout(() => {
        pushMessage(MessageFlow.SENHA);
      }, 600);
    };

    MessageFlow.SENHA.onSendMessage = (text) => {
      setFormValue('password', text);
      pushClientMessage({
        id: uuidv4(),
        text: [text],
        editableBallon: {
          type: 'password',
        },
        onEditMessage: (text) => {
          setFormValue('password', text);
          pushMessage(MessageFlow.SENHA_CONFIRMACAO);
          return text;
        },
      });
      setTimeout(() => {
        pushMessage(MessageFlow.SENHA_CONFIRMACAO);
      }, 600);
    };

    MessageFlow.CONFIRMACAO_SENHA_INVALIDA.callback = () =>
      pushMessage(MessageFlow.SENHA_CONFIRMACAO, 1);

    const registerUser = async () => {
      const payload = {
        name: formData.name,
        identityNumber: Number(formData.cpfCnpj),
        cellphone: formData.cellphone,
        email: formData.email,
        password: formData.password,
        fantasyName: formData.companyName,
        mainSocialNetwork: formData.socialPlatform,
        nameSocialNetwork: formData.socialUser,
        typeActiveAccount: formData.confirmationType,
        token: formData.confirmationCode,
      };
      try {
        const { data } = await postUser(payload);
        sessionStorage.setItem('accessToken', data.data.access_token);

        await onboardingStatus.getStatus();
        return data;
      } catch (e) {
        throw e;
      }
    };

    MessageFlow.SENHA_CONFIRMACAO.onSendMessage = async (text) => {
      pushClientMessage({
        id: uuidv4(),
        text: [text],
        editableBallon: {
          type: 'password',
          edit: false,
        },
      });

      if (text !== formData.password) {
        setTimeout(() => {
          pushMessage(MessageFlow.CONFIRMACAO_SENHA_INVALIDA);
        }, 600);
      } else {
        setFormValue('confirmPassword', text);
        setTimeout(() => {
          pushMessage(MessageFlow.ENVIAR_CODIGO_CONFIRMACAO);
        }, 600);
      }
    };

    const sendConfirmationCode = async (type, value) => {
      try {
        const { data } = await sendConfirmationCodeToUser(
          type === 'sms' ? 'cellphone' : 'email',
          value
        );
        return data;
      } catch (e) {
        notification.showError(e.message);
      }
    };
    MessageFlow.ENVIAR_CODIGO_CONFIRMACAO.optionOnClick = async (
      value,
      label
    ) => {
      pushClientMessage({
        id: uuidv4(),
        text: [label],
      });
      setFormValue('confirmationType', value);
      if (value === 'email') {
        setFormValue('confirmationContact', formData.email);
      } else {
        setFormValue('confirmationContact', formData.cellphone);
      }
      await sendConfirmationCode(
        value,
        value === 'email' ? formData.email : formData.cellphone
      );
      setTimeout(() => {
        pushMessage(MessageFlow.INFORMAR_CODIGO_CONFIRMACAO, 0, true);
      }, 600);
    };

    MessageFlow.CONFIRMATION_CODE_ERROR.callback = () =>
      pushMessage(MessageFlow.INFORMAR_CODIGO_CONFIRMACAO, 1);

    MessageFlow.INFORMAR_CODIGO_CONFIRMACAO.onSendMessage = async (text) => {
      setFormValue('confirmationCode', text);
      pushClientMessage({
        id: uuidv4(),
        text: [text],
      });
      try {
        await registerUser();
        setTimeout(() => {
          pushMessage(MessageFlow.TERMO_DE_USO);
        }, 600);
      } catch (e) {
        pushMessage(MessageFlow.CONFIRMATION_CODE_ERROR);
      }
    };

    MessageFlow.INFORMAR_CODIGO_CONFIRMACAO.optionOnClick = async (
      value,
      label
    ) => {
      pushClientMessage({
        id: uuidv4(),
        text: [label],
      });

      await sendConfirmationCode(
        formData.confirmationType,
        formData.confirmationContact
      );
      setTimeout(() => {
        pushMessage(MessageFlow.INFORMAR_CODIGO_CONFIRMACAO);
      }, 600);
    };

    MessageFlow.TERMO_DE_USO.optionOnClick = async (value, label) => {
      pushClientMessage({
        id: uuidv4(),
        text: [label],
      });
      setTimeout(() => {
        pushMessage(MessageFlow.ACEITE_TERMOS);
        setTermsAccepted(false);
      }, 600);
    };

    MessageFlow.ACEITE_TERMOS.onSendMessage = async (text) => {
      pushClientMessage({
        id: uuidv4(),
        text: [text],
      });
    };

    MessageFlow.AGRADECIMENTO_CADASTRO.callback = async () => {
      setTimeout(() => {
        history.push('/');
      }, 1000);
    };
  };
  const done = async () => {
    setTimeout(() => {
      setTermsAccepted(true);
      pushClientMessage({
        id: uuidv4(),
        text: ['Aceito'],
      });
      setTimeout(() => {
        pushMessage(MessageFlow.AGRADECIMENTO_CADASTRO, 0, true);
      }, 600);
    }, 600);
  };

  React.useEffect(() => {
    setFlowBindings(chatData);
    setTimeout(() => {
      pushMessage(chatData.activeMessage);
    }, 800);
  }, []);

  React.useEffect(() => {
    setFlowBindings(chatData);
  }, [chatData, formData]);

  return (
    <EnterAnimation
      style={{ display: 'flex', flex: 1, height: '100%' }}
      direction={animationClass}
      scaled={scaledAnimation}
      onTransitionEnd={() => openRegisterForm(false)}
    >
      <S.ChatContainer>
        {!isTermsAccepted ? (
          <Term termType="use" isOpen={!isTermsAccepted} onAccept={done} />
        ) : null}
        <S.ChatHeader>
          <S.CloseButton onClick={() => setAnimationClass('out')}>
            <img src={CloseIcon} width={24} height={24} alt="Fechar" />
          </S.CloseButton>
          <S.ChatTitle>
            <b>Cadastre-se</b>
          </S.ChatTitle>
        </S.ChatHeader>

        <S.ChatBody>
          <S.ChatBodyContainer id="scrollable">
            {messages.map((item) => (
              <MessageBox key={item.id} messageItem={item} />
            ))}
          </S.ChatBodyContainer>
        </S.ChatBody>
        <S.ChatBottom>
          <S.ChatInput
            autoFocus={!chatData.activeMessage?.inputProps.disabled}
            value={inputText}
            type={chatData.activeMessage?.inputProps.type || 'text'}
            maxLength={chatData.activeMessage?.inputProps.maxLength}
            onChange={(e) => {
              let text = e.target.value;
              if (chatData.activeMessage?.inputProps.replaceEditorRegex) {
                text = text.replace(
                  chatData.activeMessage.inputProps.replaceEditorRegex,
                  ''
                );
              }
              setFormValue(chatData.activeMessage.inputProps.name, text);
              setInputText(text);
            }}
            onKeyDown={({ key }) => {
              if (key == 'Enter') {
                if (inputText.trim().length > 0) {
                  setFormValue(
                    chatData.activeMessage.inputProps.name,
                    inputText
                  );
                  chatData.activeMessage?.onSendMessage(inputText);
                  setInputText('');
                }
              }
            }}
            disabled={chatData.activeMessage?.inputProps.disabled}
            placeholder="Enviar mensagem..."
          />
          <S.SendMessage
            disabled={chatData.activeMessage?.inputProps.disabled}
            onClick={() => {
              !chatData.activeMessage?.inputProps.disabled &&
                inputText.trim().length > 0 &&
                chatData.activeMessage?.onSendMessage(inputText);
              setInputText('');
            }}
          >
            <img src={SendMessageIcon} alt="Enviar Mensagem" />
          </S.SendMessage>
        </S.ChatBottom>
      </S.ChatContainer>
    </EnterAnimation>
  );
};

Chat.defaultProps = {
  scaledAnimation: false,
};

Chat.propTypes = {
  openRegisterForm: PropTypes.func.isRequired,
  scaledAnimation: PropTypes.bool,
  loginFacebook: PropTypes.func.isRequired,
  loginGoogle: PropTypes.func.isRequired,
};

export default withRouter(Chat);
