import styled from 'styled-components';

export const Container = styled.div`

  background: #eee;
  text-align: justify;
  padding: 60px;
  h1 {
    font-size: 24px;
  }

`;