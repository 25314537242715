import ServiceBase from './service.base';

export default class InfluencerCampaignService extends ServiceBase {
  async getStats() {
    try {
      const data = await this.get('/influencer-campaign-stats');
      return data;
    } catch (e) {
      if (e.type === 'INFLUENCER_DATA_NOT_FOUND') {
        return null;
      }
      throw e;
    }
  }

  activeCampaignByGuid(campaignGuid) {
    try {
      const data = this.post(`/influencer-campaign/${campaignGuid}`);
      return data;
    } catch (e) {
      return null;
    }
  }

  getResults(term = '', page = 0, expiresDay, categoryId, pageSize = 5) {
    let params = '';
    if (categoryId) params += `&categoryId=${categoryId}`;
    if (expiresDay) params += `&daysUntilExpires=${expiresDay}`;
    if (pageSize) params += `&pageSize=${pageSize}`;
    if (term) params += `&term=${term}`;

    return this.get(`/influencer/results?pageIndex=${page}${params}`);
  }

  getResultsAnalytics(expiresDay, categoryId) {
    let params = '';
    if (categoryId) params += `&categoryId=${categoryId}`;
    if (expiresDay) params += `&daysUntilExpires=${expiresDay}`;

    return this.get(`/influencer/results/analytics?${params}`, {
      responseType: 'arraybuffer',
    });
  }
}
