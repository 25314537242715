import React from 'react';
import PropTypes from 'prop-types';
import Chat from '../../../../components/chat';

const RegisterForm = ({
  openRegisterForm,
  scaledAnimation,
  openRememberPasswordForm,
  loginFacebook,
  loginGoogle,
}) => (
  <Chat
    openRegisterForm={openRegisterForm}
    scaledAnimation={scaledAnimation}
    openRememberPasswordForm={openRememberPasswordForm}
    loginFacebook={loginFacebook}
    loginGoogle={loginGoogle}
  />
);

RegisterForm.defaultProps = {
  scaledAnimation: false,
};

RegisterForm.propTypes = {
  openRegisterForm: PropTypes.func.isRequired,
  openRememberPasswordForm: PropTypes.func.isRequired,
  loginFacebook: PropTypes.func.isRequired,
  loginGoogle: PropTypes.func.isRequired,
  scaledAnimation: PropTypes.bool,
};

export default RegisterForm;
