import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import Global from './assets/css/global';
import reportWebVitals from './reportWebVitals';
import Theme from './assets/css/theme';
import Routes from './routes';
import { GoogleAuthProvider } from './contexts/GoogleContext';
import NotificationProvider from './contexts/NotificationContext';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={Theme}>
      <GoogleAuthProvider>
        <NotificationProvider>
          <Global />
          <Routes />
        </NotificationProvider>
      </GoogleAuthProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
