import React from 'react';
import PropTypes from 'prop-types';
import * as S from './style';

const Paragraph = ({
  children,
  micro,
  small,
  medium,
  large,
  extralarge,
  dark,
  light,
  center,
  margin,
  justify,
  pink,
  style,
  className,
}) => (
  <S.Text
    micro={micro}
    small={small}
    medium={medium}
    large={large}
    extralarge={extralarge}
    dark={dark}
    light={light}
    center={center}
    margin={margin}
    justify={justify}
    pink={pink}
    style={style}
    className={className}
  >
    {children}
  </S.Text>
);

const Bold = ({ children, medium, large, extralarge, dark, light }) => (
  <S.Bold
    medium={medium}
    large={large}
    extralarge={extralarge}
    dark={dark}
    light={light}
  >
    {children}
  </S.Bold>
);

const Title = ({
  children,
  micro,
  small,
  medium,
  large,
  dark,
  light,
  center,
  pink,
  customColor,
  className,
  margin,
}) => (
  <S.Title
    micro={micro}
    small={small}
    medium={medium}
    large={large}
    dark={dark}
    light={light}
    center={center}
    pink={pink}
    customColor={customColor}
    className={className}
    margin={margin}
  >
    {children}
  </S.Title>
);

Paragraph.propTypes = {
  children: PropTypes.node.isRequired,
  micro: PropTypes.bool,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  extralarge: PropTypes.bool,
  dark: PropTypes.bool,
  light: PropTypes.bool,
  center: PropTypes.bool,
  justify: PropTypes.bool,
  margin: PropTypes.string,
  pink: PropTypes.bool,
  style: PropTypes.string,
  className: PropTypes.string,
};

Paragraph.defaultProps = {
  micro: false,
  small: false,
  medium: true,
  large: false,
  extralarge: false,
  dark: false,
  light: false,
  center: false,
  justify: false,
  margin: null,
  pink: false,
  style: null,
  className: null,
};

Bold.propTypes = {
  children: PropTypes.node.isRequired,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  extralarge: PropTypes.bool,
  dark: PropTypes.bool,
  light: PropTypes.bool,
};

Bold.defaultProps = {
  medium: true,
  large: false,
  extralarge: false,
  dark: false,
  light: false,
};

Title.propTypes = {
  children: PropTypes.node.isRequired,
  micro: PropTypes.bool,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  dark: PropTypes.bool,
  light: PropTypes.bool,
  center: PropTypes.bool,
  pink: PropTypes.bool,
  customColor: PropTypes.string,
  className: PropTypes.string,
  margin: PropTypes.string,
};

Title.defaultProps = {
  medium: true,
  micro: false,
  small: false,
  large: false,
  dark: false,
  light: false,
  center: false,
  pink: false,
  customColor: '',
  className: null,
  margin: null,
};

export { Paragraph, Bold, Title };
