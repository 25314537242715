import axios from 'axios';
import { isLoggedIn, isclientCredentialsExpired } from '../utils/auth';

// const TokenManager = new TokenManager();

const customAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api/v1`,
  timeout: 20000,
})

const setclientCredentials = async () => {
  const { data: { data } } = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/connect/token`, {
    client_id: process.env.REACT_APP_CLIENT_ID,
  });
  sessionStorage.setItem('clientCredentials', data.client_token);
}

let isclientCredentialsRefreshed = false;

customAxios.interceptors.response.use(
  response => {
    if (response.status === 401) {
      window.location = '/login';
      sessionStorage.removeItem('accessToken');
      sessionStorage.removeItem('refreshToken');
    }
    return response;
  },
  async error => {
    if (error.response?.data.code === 401) {
      if (error.response?.data.data.error === 'invalid client_token') {
        if (!isclientCredentialsRefreshed) {
          await setclientCredentials();
          isclientCredentialsRefreshed = true;
          const { request } = error;
          request.config.headers.Authorization = `Bearer ${sessionStorage.getItem('clientCredentials')}`;
          const ret = await customAxios.request(request.config);
          return ret;
        }
        isclientCredentialsRefreshed = false;
      }
    }
    return Promise.reject(error)
  }
)


customAxios.interceptors.request.use(
  async request => {
    if (request.headers.isAuth) {

      if (!isLoggedIn()) {
        const refreshToken = sessionStorage.getItem('refreshToken');
        if (refreshToken) {
          const { data } = await customAxios.post('auth/refresh-token', { refresh_token: refreshToken }, { headers: { isClientCredentials: true } });
          sessionStorage.setItem('accessToken', data.access_token);
          sessionStorage.setItem('refreshToken', data.refresh_token);
          return request;
        }
        window.location = '/login';
        return {};
      }

      request.headers.Authorization = `Bearer ${sessionStorage.getItem('accessToken')}`;
      return request;
    }

    if (request.headers.isClientCredentials) {
      if (!sessionStorage.getItem('clientCredentials')) {
        await setclientCredentials();
      } else if (isclientCredentialsExpired()) {
        await setclientCredentials();
      }
      request.headers.Authorization = `Bearer ${sessionStorage.getItem('clientCredentials')}`;
    }
    return request;
  },
  error => Promise.reject(error)
)

export default customAxios;