import styled, { css } from 'styled-components';
import px2vw from '../../../utils/px2vw';
import BgLogin from '../../../assets/images/home/bg_login.png'
import WomanLogin from '../../../assets/images/home/woman_login.png'

export const WomanImg = styled.img.attrs({ src: WomanLogin, alt: '' })`
  width: ${px2vw(900, 1440)};
  margin: 0;
  padding: 0;
  ${props => props.theme.media['sm-down'](css`
    width: ${px2vw(500, 425)};
    position: absolute;    
    right: -40px;
    bottom: 0;
    max-width: 600px;
  `)}
`;

export const Woman = styled.div`
  position: absolute;
  bottom: -20px;
  right: -100px;
  ${props => props.theme.media['sm-down'](css`
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  `)}
`;

const BackgroundDesktop = styled.div`
  height: 100vh;
  width: 100%;
  background-size: cover;
  background: url(${BgLogin}) no-repeat;
  position: relative;
  overflow: hidden;
`;

const LinearBackground = styled.div`
  background:  linear-gradient(122.12deg, rgba(255, 0, 145, 0.65) 17.33%, rgba(255, 81, 80, 0.65) 39.92%, rgba(255, 180, 0, 0.65) 61.56%);
  height: 100vh;
  position: relative;
  width: 100%;

  ${props => props.theme.media['sm-down'](css`
    width: 100%;
    min-height: 400px;
    height: 45vh;
  `)}
`;

const BackgroundMobile = styled.div`
  width: 100%;
  background: url(${BgLogin}) no-repeat;
  background-size: cover;
  background-position-x: -100px;
  min-height: 400px;
  height: 45vh;
  position: relative;
  overflow: hidden;
`;

const LogoMobileWrapper = styled.div`
  padding: 24px;
`;





export { BackgroundDesktop, BackgroundMobile, LogoMobileWrapper, LinearBackground };