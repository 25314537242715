import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as S from './style';

import { login } from '../../../../services/auth';
import { Paragraph, Bold } from '../../../../components/text';
import Loader from '../../../../components/loader';
import { EnterAnimation } from '../../../../components/animation';
import { Input } from '../../../../components/input';
import ErrorBox from '../../../../components/alert-error';
import { Flex } from '../../../../components/layout';
import {
  Button,
  SocialButton,
  LinkedButton,
} from '../../../../components/button';
import CloseIcon from '../../../../assets/icons/close.svg';

import { useOnboardingStatus } from '../../../../contexts/OnboardingStatusContext';

const LoginForm = ({
  openLoginForm,
  scaledAnimation,
  openRememberPasswordForm,
  openRegisterForm,
  history,
  loginFacebook,
  loginGoogle,
}) => {
  const [loginForm, setLoginForm] = React.useState({
    username: '',
    password: '',
  });

  const [error, setError] = React.useState('');
  const [activeButton, setActiveButton] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const onboardingStatus = useOnboardingStatus();

  const [animationClass, setAnimationClass] = React.useState('in');

  const logIn = async () => {
    try {
      setIsLoading(true);
      const { username, password } = loginForm;
      const payload = { password };
      if (username.includes('@')) {
        payload.email = username;
      } else {
        payload.identityNumber = username.replace(/\D/g, '');
      }
      const { data } = await login(payload);
      if (data.data.access_token) {
        sessionStorage.setItem('accessToken', data.data.access_token);
        setIsLoading(false);
        await onboardingStatus.getStatus();
        history.push('/');
      }
    } catch (e) {
      setError('Usuário ou senha inválidos');
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (loginForm.username && loginForm.password) {
      setActiveButton(true);
    } else {
      setActiveButton(false);
    }
  }, [loginForm]);

  return (
    <EnterAnimation
      style={{ display: 'flex', flex: 1, height: '100%' }}
      direction={animationClass}
      scaled={scaledAnimation}
      onTransitionEnd={() => openLoginForm(false)}
    >
      <Loader isLoading={isLoading} />
      <S.CloseButton onClick={() => setAnimationClass('out')}>
        <img src={CloseIcon} width={24} height={24} alt="Fechar" />
      </S.CloseButton>
      <S.FormWrapper style={{ flex: 1 }}>
        <S.Container style={{ flex: 1 }}>
          <Paragraph>
            <Bold>Já tem conta?</Bold>
          </Paragraph>
          <Flex margin="24px 0 10px 0">
            <Paragraph>
              Digite seus dados de acesso nos campos abaixo e clique no botão{' '}
              <Bold>Entrar</Bold>.
            </Paragraph>
            {error && (
              <ErrorBox timing={4000} onTransitionEnd={() => setError('')}>
                {error}
              </ErrorBox>
            )}
          </Flex>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              logIn();
            }}
          >
            <Flex size={1} margin="0 0 15px 0">
              <Input
                required
                invalidText="Campo obrigatório"
                size="fullWidth"
                label="Seu e-mail ou CPF/CNPJ"
                name="username"
                value={loginForm.username}
                mask="cpfcnpjemail"
                onChange={(e) =>
                  setLoginForm({ ...loginForm, username: e.target.value })
                }
              />
              <Input
                required
                invalidText="Campo obrigatório"
                size="fullWidth"
                label="Sua senha"
                name="password"
                type="password"
                value={loginForm.password}
                onChange={(e) =>
                  setLoginForm({ ...loginForm, password: e.target.value })
                }
              />
              <Flex alignItems="flex-end">
                <LinkedButton
                  onClick={() => {
                    setAnimationClass('out');
                    openRememberPasswordForm(true);
                  }}
                >
                  Esqueceu sua senha?
                </LinkedButton>
              </Flex>
            </Flex>

            <Flex margin="15px 0 0 0">
              <Button size="fullWidth" secondary={!activeButton} type="submit">
                Entrar
              </Button>
            </Flex>
          </form>
          <Flex hideOn="md-up" margin="45px 0 0 0">
            <hr />
            <S.OrText>ou</S.OrText>
            <Flex margin="15px 0 15px 0">
              <SocialButton
                type="google"
                size="fullWidth"
                onClick={loginGoogle}
              >
                Entrar com o Google
              </SocialButton>
            </Flex>
            <Flex margin="0 0 15px 0">
              <SocialButton
                type="facebook"
                size="fullWidth"
                onClick={loginFacebook}
              >
                Entrar com o Facebook
              </SocialButton>
            </Flex>
            {/* <Flex margin="0 0 15px 0">
              <SocialButton
                type="instagram"
                size="fullWidth"
                onClick={() => {}}
              >
                Entrar com o Instagram
              </SocialButton>
            </Flex> */}
          </Flex>
          <Flex>
            <Paragraph center margin="15px 0">
              <Bold>Ainda não tenho conta?</Bold>
            </Paragraph>
            <Button
              size="fullWidth"
              onClick={() => {
                setAnimationClass('out');
                openRegisterForm(true);
              }}
            >
              Cadastrar
            </Button>
          </Flex>
        </S.Container>
      </S.FormWrapper>
    </EnterAnimation>
  );
};

LoginForm.defaultProps = {
  scaledAnimation: false,
};

LoginForm.propTypes = {
  openLoginForm: PropTypes.func.isRequired,
  openRememberPasswordForm: PropTypes.func.isRequired,
  openRegisterForm: PropTypes.func.isRequired,
  scaledAnimation: PropTypes.bool,
  history: PropTypes.object.isRequired,
  loginFacebook: PropTypes.func.isRequired,
  loginGoogle: PropTypes.func.isRequired,
};

export default withRouter(LoginForm);
