/* eslint-disable */
export const validarCPF = (cpf) => {
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf == '') return false;
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length != 11 ||
    cpf == '00000000000' ||
    cpf == '11111111111' ||
    cpf == '22222222222' ||
    cpf == '33333333333' ||
    cpf == '44444444444' ||
    cpf == '55555555555' ||
    cpf == '66666666666' ||
    cpf == '77777777777' ||
    cpf == '88888888888' ||
    cpf == '99999999999'
  )
    return false;
  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) rev = 0;
  if (rev != parseInt(cpf.charAt(9))) return false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) rev = 0;
  if (rev != parseInt(cpf.charAt(10))) return false;
  return true;
};

export const validarCNPJ = (cnpj) => {
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj == '') return false;

  if (cnpj.length != 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj == '00000000000000' ||
    cnpj == '11111111111111' ||
    cnpj == '22222222222222' ||
    cnpj == '33333333333333' ||
    cnpj == '44444444444444' ||
    cnpj == '55555555555555' ||
    cnpj == '66666666666666' ||
    cnpj == '77777777777777' ||
    cnpj == '88888888888888' ||
    cnpj == '99999999999999'
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(1)) return false;

  return true;
};

/* Utility function to convert a canvas to a BLOB */
export const dataURLToBlob = (dataURL, name) => {
  let BASE64_MARKER = ';base64,';
  if (dataURL.indexOf(BASE64_MARKER) == -1) {
    const parts = dataURL.split(',');
    const contentType = parts[0].split(':')[1];
    const raw = parts[1];

    const blob = new Blob([raw], { type: contentType });
    const modified = new Date();
    var file = new File([blob], name, { lastModified: modified.getTime() });
    return file;
  }

  const parts = dataURL.split(BASE64_MARKER);
  const contentType = parts[0].split(':')[1];
  const raw = window.atob(parts[1]);
  const rawLength = raw.length;

  const uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  const blob = new Blob([uInt8Array], { type: contentType });
  const modified = new Date();
  blob.lastModified = modified.getTime();
  var file = new File([blob], name, { lastModified: modified.getTime() });
  return file;
};
/* End Utility function to convert a canvas to a BLOB      */

export const convertBase64ToBlob = (base64Image) => {
  // Split into two parts
  const parts = base64Image.split(';base64,');

  // Hold the content type
  const imageType = parts[0].split(':')[1];

  // Decode Base64 string
  const decodedData = window.atob(parts[1]);

  // Create UNIT8ARRAY of size same as row data length
  const uInt8Array = new Uint8Array(decodedData.length);

  // Insert all character code into uInt8Array
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i);
  }

  // Return BLOB image after conversion
  return new Blob([uInt8Array], { type: imageType });
};

export const resizeImage = (imageFile) =>
  new Promise((resolve, reject) => {
    /// Ensure it's an image
    if (imageFile.type.match(/image.*/)) {
      // Load the image
      const reader = new FileReader();
      try {
        reader.onload = function (readerEvent) {
          const image = new Image();
          image.onload = function (imageEvent) {
            // Resize the image
            const canvas = document.createElement('canvas');
            const max_size = 1024;
            let // TODO : pull max size from a site config
              { width } = image;
            let { height } = image;
            if (width > height) {
              if (width > max_size) {
                height *= max_size / width;
                width = max_size;
              }
            } else if (height > max_size) {
              width *= max_size / height;
              height = max_size;
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext('2d').drawImage(image, 0, 0, width, height);
            const dataUrl = canvas.toDataURL(imageFile.type);
            resolve(dataURLToBlob(dataUrl, imageFile.name));
          };
          image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(imageFile);
      } catch (e) {
        reject(e);
      }
    } else {
      resolve(imageFile);
    }
  });

export const mask = {
  phone: (tel) => {
    if (tel.length > 15) {
      tel = tel.substring(0, 15);
    }
    tel = tel.replace(/\D/g, '');
    tel = tel.replace(/^(\d)/, '($1');
    tel = tel.replace(/(.{3})(\d)/, '$1) $2');
    if (tel.length == 9) {
      tel = tel.replace(/(.{1})$/, '-$1');
    } else if (tel.length == 10) {
      tel = tel.replace(/(.{2})$/, '-$1');
    } else if (tel.length == 11) {
      tel = tel.replace(/(.{3})$/, '-$1');
    } else if (tel.length == 12) {
      tel = tel.replace(/(.{4})$/, '-$1');
    } else if (tel.length > 12) {
      tel = tel.replace(/(.{4})$/, '-$1');
    }
    return tel;
  },
  cnpj: (cnpj) => {
    if (cnpj.length > 18) {
      cnpj = cnpj.substring(0, 18);
    }
    cnpj = cnpj.replace(/\D/g, '');
    cnpj = cnpj.replace(/^(\d{2})(\d)/, '$1.$2');
    cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    cnpj = cnpj.replace(/\.(\d{3})(\d)/, '.$1/$2');
    cnpj = cnpj.replace(/(\d{4})(\d)/, '$1-$2');
    return cnpj;
  },
  cpf: (cpf) => {
    cpf = cpf.replace(/\D/g, '');
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    return cpf;
  },
  email: (email) => {
    return email;
  },
  cpfcnpjemail: (cpfcnpjemail) => {
    if (!cpfcnpjemail.match(/^[/0-9.-]+$/)) {
      return mask.email(cpfcnpjemail);
    } else {
      if (cpfcnpjemail.length < 15) return mask.cpf(cpfcnpjemail);
      return mask.cnpj(cpfcnpjemail);
    }
  },
  zipCode: (cep) => {
    cep = cep.replace(/\D/g, '');
    cep = cep.replace(/^(\d{2})(\d)/, '$1.$2');
    cep = cep.replace(/\.(\d{3})(\d)/, '.$1-$2');
    return cep;
  },
  issuingAgency: (orgao) => {
    orgao = orgao.replace(/\d/g, '');
    orgao = orgao.replace(/^([a-zA-Z]{3})([a-zA-Z])/, '$1/$2');
    orgao = orgao.toUpperCase();
    return orgao;
  },
  numeric: (num) => {
    num = num.replace(/\D/g, '');
    return num;
  },
};

export const getFileNameAndExtension = (file) => {
  const name = file.name;
  const lastDot = name.lastIndexOf('.');

  const fileName = name.substring(0, lastDot);
  const ext = name.substring(lastDot + 1);
  return {
    fileName,
    extension: ext,
  };
};
