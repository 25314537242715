import styled, { css } from 'styled-components';
import { LinkedButton, Button, SocialButton } from '../button';

export const ChatContainer = styled.div`
  flex: 1;
  border-radius: 15px;
  box-shadow: 0 0px 20px #72717154;
  background-color: white;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  ${(props) =>
    props.theme.media['sm-down'](css`
      width: 100%;
      height: 100%;
    `)}
`;

export const ChatTitle = styled.p`
  font-family: ${(props) => props.theme.font.nunito};
  color: ${(props) => props.theme.colors.gray};
`;

export const ChatHeader = styled.div`
  border-bottom: 1px solid #ddd;
  padding: 15px 30px;
`;

export const ChatInput = styled.input`
  width: 100%;
  font-size: ${(props) => props.theme.fsize.paragraph.micro}px;
  font-family: ${(props) => props.theme.font.nunito};
  color: ${(props) => props.theme.colors.graydark};
  border: 0;
  padding: 15px;
  padding-right: 60px;
  outline: none;
`;

export const SendMessage = styled.div`
  position: absolute;
  width: 40px;
  height: 30px;
  right: 10px;
  top: 10px;
  padding: 5px;
  border-radius: 5px;
  :hover {
    background-color: #00000022;
  }
  cursor: pointer;
  ${(props) =>
    props.disabled &&
    css`
      :hover {
        background-color: transparent;
      }
      cursor: default;
      img {
        opacity: 0.5;
      }
    `}
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 15px;
  top: 10px;
  padding: 4px;
  width: 32px;
  height: 32px;
  border-radius: 22.5px;
  cursor: pointer;
  z-index: 100;
  :hover {
    background-color: #00000022;
  }
`;

export const ChatBodyContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 53px 0 50px 0;
  padding: 30px 15px;
  display: flex;
  flex-direction: column-reverse;
  overflow: auto;
  overflow-wrap: break-word;
`;

export const ChatBody = styled.div`
  padding: 30px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ChatBottom = styled.div`
  position: relative;
  border-top: 1px solid #ddd;
`;

export const MessageScale = styled.div`
  min-width: 150px;
  transform: scale(0);
  transition: left 200ms, right 200ms;
  position: relative;
`;

export const Avatar = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;
`;

export const MessageTextArrow = styled.div``;

export const MessageTimestamp = styled.div`
  margin-top: 5px;
`;

export const MessageText = styled.div`
  padding: 15px;
  max-width: 200px;
  transition: width 200ms;
  font-size: ${(props) => props.theme.fsize.paragraph.micro}px;
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-top: 30px;
  font-family: ${(props) => props.theme.font.nunito};
  ${(props) =>
    props.uffa
      ? css`
          justify-content: flex-start;
          ${MessageText} {
            background-color: #ececec;
            border-top-right-radius: 15px;
            border-top-left-radius: 15px;
            border-bottom-right-radius: 15px;
            color: ${props.theme.colors.graydark};
            margin-left: 15px;
            ${MessageTextArrow} {
              width: 0px;
              border: 5px solid #ececec;
              border-top-color: transparent;
              border-left-color: transparent;
              position: absolute;
              left: 8px;
              margin-top: 5px;
            }
          }
          ${MessageTimestamp} {
            text-align: left;
            margin-left: 15px;
            font-size: 12px;
          }
          ${MessageScale} {
            right: 50px;
          }
          &.in {
            ${MessageScale} {
              animation: scaleAnimation 300ms;
              transform: scale(1);
              right: 0;
            }
          }
        `
      : css`
          flex-direction: row-reverse;
          justify-content: flex-start;
          ${MessageText} {
            font-weight: 700;
            color: ${props.theme.colors.graydark};
            background-color: #fdb32b;
            border-top-right-radius: 15px;
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
            margin-right: 15px;
            ${MessageTextArrow} {
              width: 0px;
              border: 5px solid #fdb32b;
              border-top-color: transparent;
              border-right-color: transparent;
              position: absolute;
              right: 8px;
              margin-top: 5px;
            }
          }
          ${MessageTimestamp} {
            text-align: right;
            margin-right: 15px;
            font-size: 12px;
          }
          ${MessageScale} {
            left: 50px;
          }
          &.in {
            ${MessageScale} {
              animation: scaleAnimation 300ms;
              transform: scale(1);
              left: 0;
            }
          }
        `}
  @keyframes scaleAnimation {
    0% {
      transform: scale(0);
    }
    80% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`;

export const MessageTyping = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  height: 17px;
  margin: 0 auto;
`;
export const MessageTypingDot = styled.div`
  animation: typingAnimation 1s infinite ease-in-out;
  background-color: #00000033;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
  &:nth-child(1) {
    animation-delay: 200ms;
  }
  &:nth-child(2) {
    animation-delay: 300ms;
  }
  &:nth-child(3) {
    animation-delay: 400ms;
  }
  &:last-child {
    margin-right: 0;
  }

  @keyframes typingAnimation {
    0% {
      transform: translateY(0px);
      background-color: #00000033;
    }
    28% {
      transform: translateY(-7px);
      background-color: #00000088;
    }
    44% {
      transform: translateY(0px);
      background-color: #000000bb;
    }
  }
`;

export const BallonActionButton = styled(LinkedButton)`
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  color: white !important;
  height: 20px;
  margin-top: 5px;
  font-weight: normal;
`;

export const EditableInput = styled.input`
  background: #ffffff55;
  border-radius: 3px;
  width: 100%;
  font-size: ${(props) => props.theme.fsize.paragraph.micro}px;
  font-family: ${(props) => props.theme.font.nunito};
  font-weight: 700;
  border: 0;
  height: 30px;
  margin-right: 10px;
  padding: 3px 10px;
  outline: none;
`;

export const OptionButtonsWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
`;

export const OptionButton = styled(Button)`
  background-color: ${(props) => props.theme.colors[props.customBg]};
  margin-right: 10px;
  font-size: 12px;
  height: 30px;
  width: 100px;
`;

export const SocialOptionButtonsWrapper = styled.div`
  display: grid;
  grid-template-rows: repeat(auto-fill, 60px);
  grid-template-columns: 60px 60px 60px;
  margin-top: 15px;
  margin-left: 40px;
`;

export const SocialOptionButton = styled(SocialButton)`
  margin-right: 10px;
`;
