import React from 'react';
import PropTypes from 'prop-types';
import S from './style';
// import { FlexWrapper } from '../layout';
import IconGoogle from '../../assets/icons/icon_google.svg';
import IconFacebook from '../../assets/icons/icon_facebook.svg';
import IconInstagram from '../../assets/icons/icon_instagram.svg';
import BtnYoutube from '../../assets/icons/button_youtube.svg';
import BtnLinkedin from '../../assets/icons/button_linkedin.svg';
import BtnTikTok from '../../assets/icons/button_tiktok.svg';
import BtnTwitter from '../../assets/icons/button_twitter.svg';
import BtnGoogle from '../../assets/icons/button_google.svg';
import BtnFacebook from '../../assets/icons/button_facebook.svg';
import BtnInstagram from '../../assets/icons/button_instagram.svg';

const LinkedButton = ({ children, secondary, size, onClick, className }) => (
  <S.LinkedButton
    secondary={secondary}
    onClick={onClick}
    size={size}
    className={className}
    type="button"
  >
    {children}
  </S.LinkedButton>
);

const Button = ({
  children,
  secondary,
  size,
  onClick,
  yellow,
  className,
  type,
  whiteButton,
  customBgColor,
  customColor,
  customBorderColor,
  disabled,
  active,
}) => (
  <S.Button
    secondary={secondary}
    onClick={onClick}
    yellow={yellow}
    size={size}
    type={type}
    whiteButton={whiteButton}
    className={className}
    customBgColor={customBgColor}
    customColor={customColor}
    customBorderColor={customBorderColor}
    disabled={disabled}
    active={active}
  >
    {children}
  </S.Button>
);

const SocialButton = ({ size, type, mini, onClick, children }) => (
  <S.SocialButton onClick={onClick} type={type} size={size} mini={mini}>
    {!mini && (
      <>
        <S.SocialContent>
          <S.WrapperIcon>
            {type === 'google' && <img src={IconGoogle} alt="Google" />}
            {type === 'facebook' && <img src={IconFacebook} alt="Facebook" />}
            {type === 'instagram' && (
              <img src={IconInstagram} alt="Instagram" />
            )}
          </S.WrapperIcon>
          <p style={{ textAlign: 'left', flex: 1 }}>{children}</p>
        </S.SocialContent>
      </>
    )}
    {mini && (
      <S.MiniButtonWrapper>
        {type === 'google' && <img src={BtnGoogle} alt="Google" />}
        {type === 'facebook' && <img src={BtnFacebook} alt="Facebook" />}
        {type === 'instagram' && <img src={BtnInstagram} alt="Instagram" />}
        {type === 'youtube' && <img src={BtnYoutube} alt="Youtube" />}
        {type === 'linkedin' && <img src={BtnLinkedin} alt="Linkedin" />}
        {type === 'tiktok' && <img src={BtnTikTok} alt="Tiktok" />}
        {type === 'twitter' && <img src={BtnTwitter} alt="Twitter" />}
      </S.MiniButtonWrapper>
    )}
  </S.SocialButton>
);

SocialButton.defaultProps = {
  size: null,
  children: null,
  mini: false,
};

SocialButton.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large', 'fullWidth', null]),
  type: PropTypes.oneOf([
    'google',
    'instagram',
    'facebook',
    'twitter',
    'youtube',
    'linkedin',
    'tiktok',
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.string,
  mini: PropTypes.bool,
};

Button.defaultProps = {
  secondary: false,
  yellow: false,
  whiteButton: false,
  size: 'medium',
  className: '',
  type: 'button',
  customBgColor: '',
  customColor: '',
  customBorderColor: '',
  disabled: false,
  active: true,
  onClick: () => {},
};

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit']),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  secondary: PropTypes.bool,
  yellow: PropTypes.bool,
  whiteButton: PropTypes.bool,
  customBgColor: PropTypes.string,
  customColor: PropTypes.string,
  customBorderColor: PropTypes.string,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'fullWidth']),
};

LinkedButton.defaultProps = {
  secondary: false,
  size: 'medium',
  className: '',
};

LinkedButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  secondary: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'fullWidth']),
};

const DoneCampaignButton = ({
  children,
  secondary,
  size,
  onClick,
  className,
  type,
  whiteButton,
  customBgColor,
  disabled,
}) => (
  <S.Button
    secondary={secondary}
    onClick={onClick}
    size={size}
    type={type}
    whiteButton={whiteButton}
    className={className}
    customBgColor={customBgColor}
    disabled={disabled}
  >
    {children}
  </S.Button>
);

DoneCampaignButton.defaultProps = {
  secondary: false,
  whiteButton: false,
  size: 'medium',
  className: 'doneCampaignButton noselected',
  type: 'submit',
  customBgColor: '',
  disabled: false,
  onClick: () => {},
};

DoneCampaignButton.propTypes = {
  type: PropTypes.oneOf(['button', 'submit']),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  secondary: PropTypes.bool,
  whiteButton: PropTypes.bool,
  customBgColor: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'fullWidth']),
};

export { Button, SocialButton, LinkedButton, DoneCampaignButton };
