import React from 'react';
// import Modal from '../../components/modal';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useGoogleAuth } from '../../contexts/GoogleContext';
import { useNotification } from '../../contexts/NotificationContext';
import { FlexWrapper, Flex, Responsive } from '../../components/layout';
import {
  BackgroundMobile,
  BackgroundDesktop,
  LoginContentDesktop,
  LoginContentMobile,
  LoginForm,
  RegisterForm,
  RememberPasswordForm,
} from './components';
import { loginSocial } from '../../services/auth';
import { useOnboardingStatus } from '../../contexts/OnboardingStatusContext';

function Login({ history }) {
  const notification = useNotification();
  const { signIn, googleUser, isSignedIn } = useGoogleAuth();

  const [openLoginForm, setOpenLoginForm] = React.useState(false);
  const [openRegisterForm, setOpenRegisterForm] = React.useState(false);
  const [openRememberPasswordForm, setOpenRememberPasswordForm] =
    React.useState(false);

  const onboardingStatus = useOnboardingStatus();

  ((d, s, id) => {
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    const js = d.createElement(s);
    js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');

  const loginFacebook = async () => {
    window.FB.init({
      appId: process.env.REACT_APP_FACEBOOK_APP_ID,
      cookie: true,
      xfbml: true,
      version: 'v12.0',
    });

    window.FB.getLoginStatus(async (response) => {
      let accessToken;
      if (response.status !== 'connected') {
        const { authResponse } = await new Promise((res) => {
          window.FB.login(
            (fbResponse) => {
              res(fbResponse);
            },
            { scope: 'email' }
          );
        });
        if (!authResponse) return;
        accessToken = authResponse.accessToken;
      } else {
        accessToken = response.authResponse.accessToken;
      }
      try {
        const { data } = await loginSocial({
          provider: 'facebook',
          accessToken,
        });
        sessionStorage.setItem('accessToken', data.data.access_token);
        await onboardingStatus.getStatus();
        history.push('/');
      } catch (e) {
        notification.showError(e.message);
      }
    });
  };

  const loginGoogle = async () => {
    let accessToken;
    if (!isSignedIn) {
      const ret = await signIn();
      accessToken = ret.accessToken;
    } else {
      accessToken = googleUser.accessToken;
    }
    try {
      const { data } = await loginSocial({
        provider: 'google',
        accessToken,
      });
      sessionStorage.setItem('accessToken', data.data.access_token);
      await onboardingStatus.getStatus();
      history.push('/');
    } catch (e) {
      notification.showError(e.message);
    }
  };

  React.useEffect(() => {
    sessionStorage.clear();
  }, []);

  return (
    <Responsive
      Mobile={() => (
        <Flex hideOn="md-up">
          <BackgroundMobile />
          <LoginContentMobile
            anyFormIsOpen={
              openLoginForm || openRegisterForm || openRememberPasswordForm
            }
            loginFacebook={loginFacebook}
            loginGoogle={loginGoogle}
            openLoginForm={setOpenLoginForm}
            openRegisterForm={setOpenRegisterForm}
            openRememberPasswordForm={setOpenRememberPasswordForm}
            formRender={
              (openRegisterForm && RegisterForm) ||
              (openLoginForm && LoginForm) ||
              (openRememberPasswordForm && RememberPasswordForm)
            }
          />
        </Flex>
      )}
      Desktop={() => (
        <Flex hideOn="sm-down">
          <FlexWrapper style={{ minHeight: '100vh' }}>
            <LoginContentDesktop
              loginFacebook={loginFacebook}
              loginGoogle={loginGoogle}
              anyFormIsOpen={
                openLoginForm || openRegisterForm || openRememberPasswordForm
              }
              openLoginForm={setOpenLoginForm}
              openRegisterForm={setOpenRegisterForm}
              openRememberPasswordForm={setOpenRememberPasswordForm}
              formRender={
                (openRegisterForm && RegisterForm) ||
                (openLoginForm && LoginForm) ||
                (openRememberPasswordForm && RememberPasswordForm)
              }
            />
            <BackgroundDesktop />
          </FlexWrapper>
        </Flex>
      )}
    />
  );

  // return (
  //   <>

  //     {/* {openLoginForm && (
  //       <Modal isOpen={openLoginForm} onClose={() => setOpenLoginForm(false)}>
  //         <LoginForm />
  //       </Modal>
  //     )} */}
  //   </>
  // );
}

Login.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Login);
