import React from 'react';
import PropTypes from 'prop-types';
import * as S from './style';

export const Toggle = ({ isActive, label, onToggle }) => (
  <S.ToggleWrapper onClick={onToggle}>
    <S.ToggleButton active={isActive} />
    <S.ToggleLabel>{label}</S.ToggleLabel>
  </S.ToggleWrapper>
);

Toggle.propTypes = {
  isActive: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
};
