import React from 'react';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';

import InfluencerRegisterService from '../../services/influencer-register.service';
import { useNotification } from '../NotificationContext';
import Loader from '../../components/loader';
import { validarCNPJ } from '../../utils';

const IRS = new InfluencerRegisterService();
let tokenDecoded = ''

if (sessionStorage.getItem('accessToken')){
  // eslint-disable-next-line no-const-assign
  tokenDecoded = jwtDecode(sessionStorage.getItem('accessToken'));
}


const createContext = () => {
  const ctx = React.createContext(undefined);

  const onboardingCtx = () => {
    const contextValue = React.useContext(ctx);

    if (contextValue === undefined)
      throw new Error('onboardingCtx must be inside a Provider with a value');

    return contextValue;
  };
  const ret = [onboardingCtx, ctx.Provider];
  return ret;
};

const [useOnboardingStatus, Provider] = createContext();

const OnboardingStatusProvider = ({ children }) => {
  const notification = useNotification();
  const [status, setStatus] = React.useState({ notReady: true });
  const [isLoading, setIsLoading] = React.useState(false);
  const [profileImage, setProfileImage] = React.useState();

  const getProfileImage = async () => {
    const data = await IRS.getProfileImage(tokenDecoded);
    setProfileImage(data);
  };

  const getStatus = async () => {
    setIsLoading(true);

    try {
      if (sessionStorage.getItem('accessToken')) {
        let personType = 'pf';
        if (tokenDecoded.identityNumber) {
          if (validarCNPJ(tokenDecoded.identityNumber)) {
            personType = 'pj';
          }
        }
        const data = await IRS.getStatus();
        setStatus({ ...data, personType });
        getProfileImage(tokenDecoded);
      }
    } catch (e) {
      notification.showError(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    getStatus();
  }, []);

  return (
    <Provider value={{ status, getStatus, profileImage, getProfileImage }}>
      <Loader fixed isLoading={isLoading} />
      {children}
    </Provider>
  );
};

OnboardingStatusProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OnboardingStatusProvider;
export { useOnboardingStatus };
