/* eslint-disable indent */
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  padding-top: 10px;
  ${(props) =>
    (props.size === 'large' &&
      css`
        width: 248px;
      `) ||
    (props.size === 'small' &&
      css`
        width: 120px;
      `) ||
    (props.size === 'medium' &&
      css`
        width: 188px;
      `) ||
    (props.size === 'fullWidth' &&
      css`
        width: 100%;
      `)}
  &.searchbox {
    padding-top: 0;
    width: 485px;
    height: 40px;
    display: flex;
    ${(props) =>
      props.theme.media.xs(css`
        width: 330px;
      `)}
  }
  ${(props) =>
    props.gridArea &&
    css`
      grid-area: ${props.gridArea};
    `}
`;

const getInvalidColor = (props) =>
  props.isInvalid ? props.theme.colors.pink : props.theme.colors.gray;

const Input = styled.input`
  background: white;
  font-size: ${(props) => props.theme.fsize.paragraph.medium}px;
  height: 35px;
  width: 100%;
  border: 0;
  border-bottom: 1px solid ${getInvalidColor};
  outline: none;
  font-family: ${(props) => props.theme.font.nunito};
  &.searchbox {
    position: absolute;
    background: rgba(85, 85, 85, 0.05);
    border-bottom: 0;
    padding-left: 44px;
    border-radius: 25px;
    width: 100%;
    height: 40px;
  }
  &.option {
    cursor: pointer;
    margin: 0;
    height: 24px;
    width: 24px;
    margin-right: 16px;
  }
`;

const RoundedInput = styled.input`
  font-size: ${(props) => props.theme.fsize.paragraph.medium};
  height: 35px;
  width: 100%;
  padding: 0 20px;
  border: 1px solid ${getInvalidColor};
  outline: none;
  font-family: ${(props) => props.theme.font.nunito};
  border-radius: 30px;
`;

const Label = styled.label`
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 18px;
  transition: 0.3s ease all;
  color: ${getInvalidColor};
  font-size: ${(props) => props.theme.fsize.paragraph.small};
  font-family: ${(props) => props.theme.font.nunito};
  ${Input}:focus ~ & , ${Input}:not(:placeholder-shown) ~ & {
    top: 0px;
    left: 0px;
    font-size: 11px;
    opacity: 0.6;
  }
  &.option {
    position: inherit;
    pointer-events: auto;
    cursor: pointer;
    font-size: ${(props) => props.theme.fsize.paragraph.micro}px;
    opacity: 1 !important;
  }
`;

const RoundedLabel = styled.label`
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 3px;
  padding: 0 5px;
  transition: 0.3s ease all;
  color: ${getInvalidColor};
  font-size: 10px;
  background: white;
  font-family: ${(props) => props.theme.font.nunito};
`;
const InvalidText = styled.p`
  color: ${(props) => props.theme.colors.pink};
  font-family: ${(props) => props.theme.font.nunito};
  font-size: ${(props) => props.theme.fsize.paragraph.small}px;
`;
const SearchIcon = styled.img`
  position: relative;
  left: 16px;
  top: 10px;
  height: 20px;
  width: 20px;
`;
export {
  Wrapper,
  Label,
  Input,
  RoundedLabel,
  RoundedInput,
  InvalidText,
  SearchIcon,
};
