import styled, { css } from 'styled-components';
import px2vw from '../../utils/px2vw';

export const Text = styled.p`
  font-family: ${(props) => props.theme.font.nunito};
  text-align: ${(props) => props.center && 'center'};
  text-align: ${(props) => props.justify && 'justify'};
  margin: ${(props) => props.margin};
  font-size: ${(props) =>
    (props.micro && px2vw(props.theme.fsize.paragraph.micro, 1920)) ||
    (props.small && px2vw(props.theme.fsize.paragraph.small, 1920)) ||
    (props.large && px2vw(props.theme.fsize.paragraph.large, 1920)) ||
    (props.extralarge && px2vw(props.theme.fsize.paragraph.extralarge, 1920)) ||
    (props.medium && px2vw(props.theme.fsize.paragraph.medium, 1920))};
  color: ${(props) =>
    (props.dark && props.theme.colors.graydark) ||
    (props.light && props.theme.colors.graylight) ||
    (props.pink && props.theme.colors.pink) ||
    (props.customColor && props.customColor) ||
    props.theme.colors.gray};
  ${(props) =>
    props.theme.media['sm-down'](css`
      font-size: ${(props.large &&
        px2vw(props.theme.fsize.paragraph.large, 1920)) ||
      (props.extralarge &&
        px2vw(props.theme.fsize.paragraph.extralarge, 1920)) ||
      (props.micro && px2vw(props.theme.fsize.paragraph.micro, 1920)) ||
      (props.small && px2vw(props.theme.fsize.paragraph.small, 1920)) ||
      (props.medium && px2vw(props.theme.fsize.paragraph.medium, 1920))};
    `)};
  &.ranking {
  }
`;

export const Title = styled.h1`
  font-family: ${(props) => props.theme.font.nunito};
  text-align: ${(props) => props.center && 'center'};
  margin: ${(props) => props.margin};
  font-weight: 700;
  font-size: ${(props) =>
    (props.large && px2vw(props.theme.fsize.title.large, 1920)) ||
    (props.small && px2vw(props.theme.fsize.title.small, 1920)) ||
    (props.micro && px2vw(props.theme.fsize.title.micro, 1920)) ||
    (props.medium && px2vw(props.theme.fsize.title.medium, 1920))};

  ${(props) =>
    props.theme.media['sm-down'](css`
      font-size: ${(props.large &&
        px2vw(props.theme.fsize.title.large, 1920)) ||
      (props.small && px2vw(props.theme.fsize.title.small, 1920)) ||
      (props.micro && px2vw(props.theme.fsize.title.micro, 1920)) ||
      (props.medium && px2vw(props.theme.fsize.title.medium, 1920))};
    `)};

  color: ${(props) =>
    (props.dark && props.theme.colors.graydark) ||
    (props.light && props.theme.colors.graylight) ||
    (props.pink && props.theme.colors.pink) ||
    (props.customColor && props.customColor) ||
    props.theme.colors.gray};
  &.secondary {
    font-size: 24px;
  }
`;

export const Bold = styled.b`
  font-family: ${(props) => props.theme.font.nunito};
  font-weight: 700;
`;
