import React from 'react';
import { ReactComponent as WaveD } from '../../../../assets/images/home/wave_desktop.svg';
import { ReactComponent as WaveM } from '../../../../assets/images/home/wave_mobile.svg';
import * as S from './style';

export const WaveMobile = () => (
  <S.WrapperWaveMobile>
    <WaveM />
  </S.WrapperWaveMobile>
);

export const WaveDesktop = () => (
  <S.WrapperWaveDesktop>
    <WaveD />
  </S.WrapperWaveDesktop>
);
