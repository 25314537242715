import styled, { css } from 'styled-components';

export const EnterAnimation = styled.div`
  transition: opacity 0.4s, transform 0.4s;
  ${props => {
    if (props.scaled) return css`
      transform: scale(0);
      opacity: 0;
      &.in {
        opacity: 1;
        transform: scale(1);
      }
      &.out {
        opacity: 0;
        transform: scale(0);
      }
    `
    return css`
        transform: translateX(-10%);
        opacity: 0;
        &.in {
          opacity: 1;
          transform: translateX(0);

        }
        &.out {
          transition: opacity 0.2s, transform 0.2s;
          opacity: 0;
          transform: translateX(-10%);
        }
    `;
  }}
`;