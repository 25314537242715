import styled from 'styled-components';
import { ReactComponent as LoaderSvg } from '../../assets/icons/loader.svg';

export const LoaderContainer = styled.div`
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  position: ${props => props.fixed ? 'fixed' : 'absolute'};
  z-index: 1000;
  background-color: #ffffff70;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Loader = styled(LoaderSvg)`
  width: 150px;
`;
