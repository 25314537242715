import React from 'react';
import PropTypes from 'prop-types';

import { mask as inputMask } from '../../utils';

import * as S from './style';
import SearchIcon from '../../assets/icons/icon_search.svg';
import { Button } from '../button';

const Option = ({ label, name, type, size, id, onChange, checked }) => (
  <>
    <S.Input
      type={type}
      name={name}
      checked={checked}
      size={size}
      className="option"
      id={id}
      onChange={onChange}
    />
    <S.Label className="option" for={id}>
      {label}
    </S.Label>
  </>
);

Option.defaultProps = {
  size: 'medium',
  type: 'radio',
};

Option.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'fullWidth', null]),
  onChange: PropTypes.func.isRequired,
};

const Input = ({
  label,
  onChange,
  value,
  name,
  size,
  type,
  invalidText,
  invalid,
  required,
  mask,
}) => {
  if (mask && value) {
    value = inputMask[mask](`${value}`);
  }
  return (
    <S.Wrapper size={size}>
      <S.Input
        invalid={invalid}
        onChange={onChange}
        value={value}
        type={type}
        name={name}
        placeholder=" "
        required={required}
      />
      <S.Label invalid={invalid}>{label}</S.Label>
      {invalid && <S.InvalidText>{invalidText}</S.InvalidText>}
    </S.Wrapper>
  );
};

Input.defaultProps = {
  size: 'medium',
  type: 'text',
  invalidText: '',
  invalid: false,
  required: false,
  mask: '',
};

Input.propTypes = {
  mask: PropTypes.string,
  invalidText: PropTypes.string,
  invalid: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'fullWidth', null]),
};

const RoundedInput = ({
  label,
  onChange,
  value,
  name,
  size,
  type,
  invalidText,
  invalid,
  required,
  gridArea,
  mask,
  ...rest
}) => {
  if (mask && value) {
    value = inputMask[mask](`${value}`);
  }
  return (
    <S.Wrapper size={size} gridArea={gridArea}>
      <S.RoundedInput
        invalid={invalid}
        onChange={onChange}
        value={value}
        type={type}
        name={name}
        placeholder=" "
        required={required}
        {...rest}
      />
      <S.RoundedLabel invalid={invalid}>{label}</S.RoundedLabel>
      {invalid && <S.InvalidText>{invalidText}</S.InvalidText>}
    </S.Wrapper>
  );
};

RoundedInput.defaultProps = {
  size: 'medium',
  type: 'text',
  invalidText: '',
  invalid: false,
  name: null,
  required: false,
  gridArea: '',
  mask: '',
};

RoundedInput.propTypes = {
  invalidText: PropTypes.string,
  invalid: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  type: PropTypes.string,
  name: PropTypes.string,
  gridArea: PropTypes.string,
  mask: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'fullWidth', null]),
};

const Searchbox = ({
  label,
  onChange,
  value,
  size,
  type,
  invalidText,
  invalid,
  required,
  onClick,
}) => (
  <S.Wrapper size={size} className="searchbox">
    <S.SearchIcon src={SearchIcon} />
    <S.Input
      invalid={invalid}
      onChange={onChange}
      value={value}
      type={type}
      name="searchbox"
      placeholder="Ache aqui"
      required={required}
      className="searchbox"
    />
    <S.Label invalid={invalid}>{label}</S.Label>
    {invalid && <S.InvalidText>{invalidText}</S.InvalidText>}
    <Button type="submit" onClick={onClick} className="searchbutton">
      Pesquisar
    </Button>
  </S.Wrapper>
);

Searchbox.defaultProps = {
  size: 'medium',
  type: 'text',
  invalidText: '',
  invalid: false,
  required: false,
  value: '',
};

Searchbox.propTypes = {
  invalidText: PropTypes.string,
  invalid: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'fullWidth', null]),
};
export { Input, RoundedInput, Searchbox, Option };
