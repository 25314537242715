import React from 'react';
import * as S from './style';
import { Input } from '../../../components/input';
import { Button } from '../../../components/button';
import { Toggle } from '../../../components/toggle';

const ExcludeData = () => (
  <S.Container>
    <S.FormContainer>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          // todo disparar e-mail para alguem dentro do uffa
        }}
      >
        <h1>Formulário para exclusão dos dados.</h1>
        <Input label="Informe seu nome*" required size="fullWidth" />
        <Input label="Informe seu CPF*" required size="fullWidth" />
        <br />
        <Toggle
          label="Desejo excluir todos os meus dados deste site."
          isActive
          onToggle={() => {}}
        />

        <Button type="submit" size="fullWidth">
          Enviar solicitação de exclusão
        </Button>
      </form>
    </S.FormContainer>
  </S.Container>
);

export default ExcludeData;
