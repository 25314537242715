import styled, { css } from 'styled-components';

export const TitleWrapper = styled.div`
  padding: 20px 0 20px 30px;
  width: 100%;
  border-bottom: 1px solid #ededed;
`;

export const ButtonWrapper = styled.div`
  border-top: 1px solid #ededed;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  button:first-child {
    margin-right: 10px;
  }
  button:last-child {
    margin-left: 10px;
  }
  ${props => props.theme.media['sm-down'](css`
    padding: 20px 0;
  `)}
`;

export const TermWrapper = styled.div`
  max-width: 1295px;
  max-height: 450px;
  overflow: auto;
  padding: 20px 40px 20px 40px;
  ${props => props.theme.media['sm-down'](css`
    max-height: calc(100vh - 150px);
  `)}
`;
