import styled, {css} from 'styled-components';

// import { FlexWrapper } from '../../../../components/layout'

export const WrapperDesktop = styled.div`
      display: flex;
      flex-direction: column;
      ${props => props.theme.media['md-up'](css`
        width: 700px;
        padding: 40px 64px;
      `)}
`;