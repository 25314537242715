import styled, { css } from 'styled-components';
import px2vw from '../../utils/px2vw';

import AlertIcon from '../../assets/icons/icon_alert.svg';


export const Content = styled.div`
 color: ${props => props.theme.colors.gray};
  font-family: ${(props) => props.theme.font.nunito};
  font-size: ${(props) => px2vw(props.theme.fsize.paragraph.small, 1920)};
  ${(props) =>
    props.theme.media['sm-down'](css`
      font-size: ${px2vw(props.theme.fsize.paragraph.small, 425)};
    `)};
    padding: 0 15px;
    width: 100%;
`;

export const Icon = styled.img.attrs({ src: AlertIcon })`
  background: #eaeaea;
  height: 48px;
  width: 48px;
  padding: 8px;
  border-radius: 12px;
`;

export const ErrorWrapper = styled.div`
  background: #eaeaea;
  padding: 15px 24px;
  margin: 10px 0;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  transition: opacity 0.5s;
  opacity: 0;
  &.in {
    opacity: 1;
  }
  &.out {
    opacity: 0;
  }
  display: flex;

  ${props => props.isToaster && css`
    position:fixed;
    top: 15px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1010;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 220px;
    max-width: 450px;
    height: 60px;
    border-radius: 30px;
    background: ${props.isError ? '#ff514f' : '#40a737'};
    box-shadow: 0px 0px 15px rgba(0,0,0,0.2);
    
    ${Content} {
      color: white;
      font-size: 16px;
      text-align: center;
    }
    ${Icon} {
      display: none;
    }
  `}
`;

