import React from 'react';
import PropTypes from 'prop-types';
import * as S from './style';

const Loader = ({ isLoading, fixed }) =>
  isLoading ? (
    <S.LoaderContainer fixed={fixed}>
      <S.Loader />
    </S.LoaderContainer>
  ) : (
    <></>
  );

Loader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  fixed: PropTypes.bool,
};

Loader.defaultProps = {
  fixed: false,
};

export default Loader;
