import jwtDecode from 'jwt-decode';

export const isLoggedIn = () => {
  const accessToken = sessionStorage.getItem('accessToken');
  if (accessToken) {
    const decoded = jwtDecode(accessToken);
    if (decoded?.exp * 1000 > new Date().getTime()) {
      return true;
    }
    return false;
  }
  return false;
};

export const isclientCredentialsExpired = () => {
  const clientCredentials = sessionStorage.getItem('clientCredentials');
  if (clientCredentials) {
    const decoded = jwtDecode(clientCredentials);
    if (decoded?.exp * 1000 > new Date().getTime()) {
      return false;
    }
    return true;
  }
  return false;
};
