/* eslint-disable indent */
import styled, { css } from 'styled-components';
import px2vw from '../../utils/px2vw';

const LinkedButton = styled.button`
  background-color: transparent;
  height: 40px;
  cursor: pointer;
  outline: none;
  border: 0;
  min-width: 150px;
  font-family: ${(props) => props.theme.font.nunito};
  font-weight: 700;
  color: ${(props) => props.theme.colors.gray};
`;

const VouchersSelected = styled.span`
  left: 4px;
  height: 32px;
  width: 64px;
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.graydark};
  font-family: ${(props) => props.theme.font.nunito};
  font-weight: 700;
  cursor: pointer;
  border-radius: 20px;
  outline: none;
  border: 0;
`;

const Button = styled.button`
  height: 40px;
  background-color: ${(props) => props.theme.colors.pink};
  color: #fff;
  font-family: ${(props) => props.theme.font.nunito};
  font-weight: 700;
  ${(props) =>
    props.theme.media['md-up'](css`
      font-size: ${props.theme.fsize.paragraph.small}px;
    `)}
  ${(props) =>
    props.theme.media['sm-down'](css`
      font-size: ${props.theme.fsize.paragraph.micro}px;
    `)}
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  border-radius: 20px;
  outline: none;
  border: ${(props) =>
    props.customBorderColor ? `2px solid ${props.customBorderColor}` : 0};
  min-width: 150px;
  filter: drop-shadow(0px 4px 3px #00000044);
  ${(props) =>
    (props.size === 'large' &&
      css`
        width: 248px;
      `) ||
    (props.size === 'small' &&
      css`
        width: 120px;
        height: 30px;
      `) ||
    (props.size === 'medium' &&
      css`
        width: 188px;
      `) ||
    (props.size === 'fullWidth' &&
      css`
        width: 100%;
      `)}
  ${(props) =>
    props.secondary &&
    css`
      background-color: ${props.theme.colors.graylight};
    `};
    ${(props) =>
    props.yellow &&
    css`
        background-color: ${props.theme.colors.yellow};
      `};

  ${(props) =>
    props.customBgColor &&
    css`
      background-color: ${props.customBgColor};
    `};
  ${(props) =>
    props.customColor &&
    css`
      color: ${props.customColor};
    `};

  :hover {
    box-shadow: inset 1px 0px 20px 20px #ffffff32;
  }
  :active {
    box-shadow: inset 1px 0px 20px 20px #ffffff48;
  }

  ${(props) =>
    props.whiteButton &&
    css`
      background-color: white;
      border: 2px solid
        ${props.customBorderColor
        ? props.customBorderColor
        : props.theme.colors.pink};
      color: ${props.theme.colors.pink};
      filter: none;
    `};
  ${(props) =>
    !props.active &&
    css`
      background-color: #dbdbdb;
      color: #aaaaaa;
    `};
  &.searchbutton {
    position: absolute;
    right: 0;
    width: 125px;
    background-color: ${(props) => props.theme.colors.graymed2};
    color: ${(props) => props.theme.colors.white};
    filter: none;
    font-size: 18px;
  }
  &.doneCampaignButton {
    width: 100%;
    padding-left: 68px;
    &.noselected {
      background-color: ${(props) => props.theme.colors.graymed2};
      color: ${(props) => props.theme.colors.white};
    }
  }
  &.reactiveCampaignButton {
    background: ${(props) => props.background};
    color: ${(props) => props.theme.colors.white};
    align-self: center;
    z-index: 20;
    font-weight: 700;
    font-size: 12px;
  }
  &.detailsCampaignButton {
    height: 25px;
    width: 125px;
    min-width: auto;
    ${(props) =>
    props.theme.media.xs(css`
      height: 22px;
      width: 100px;
      }
    `)}
  }
  &.filter {
    width: auto;
    min-width: 144px;
    border-style: solid;
    border-radius: 25px;
  }
  &.downloadCampaign {
    font-size: 18px;
    width: 293px;
    height: 40px;
  }
  &.reactiveVoucherCampaign {
    width: 226px;
    height: 40px;
  }
  &.periodRanking {
    // width: 212px;
    height: 40px;
    font-size: 16px;
    min-width: unset;
  }
`;

const SocialButton = styled.button`
  height: 40px;
  width: ${(props) => props.mini && '40px'};
  outline: none;
  border: 0;
  cursor: pointer;
  font-weight: 700;
  font-family: ${(props) => props.theme.font.nunito};
  ${(props) =>
    props.theme.media['md-up'](css`
      font-size: ${px2vw(props.theme.fsize.paragraph.small, 1720)};
    `)}
  ${(props) =>
    props.theme.media['sm-down'](css`
      font-size: ${px2vw(props.theme.fsize.paragraph.small, 425)};
    `)}
    ${(props) =>
    (props.size === 'large' &&
      css`
        width: 248px;
      `) ||
    (props.size === 'small' &&
      css`
        width: 120px;
      `) ||
    (props.size === 'medium' &&
      css`
        width: 188px;
      `) ||
    (props.size === 'fullWidth' &&
      css`
        width: 100%;
      `)}
  ${(props) => {
    if (!props.mini) {
      return css`
        border-radius: 20px;
        font-weight: 700;
        min-width: 150px;
        filter: drop-shadow(0px 4px 3px #00000044);
        display: flex;
        align-items: center;
        justify-content: space-around;
      `;
    }
    return css`
      border-radius: 20px;
      z-index: 2;
    `;
  }}
  ${(props) => {
    const { type, mini } = props;
    if (type === 'google') {
      return css`
        ${!mini &&
        css`
          background-color: ${props.theme.colors.googleBtn};
        `};
        color: ${props.theme.colors.graydark};
      `;
    }
    if (type === 'facebook') {
      return css`
        ${!mini &&
        css`
          background-color: ${props.theme.colors.facebookBtn};
        `};
        color: ${props.theme.colors.white};
      `;
    }
    if (type === 'instagram') {
      return css`
        ${!mini &&
        css`
          background-color: ${props.theme.colors.instagramBtn};
        `};
        color: ${props.theme.colors.white};
      `;
    }
    return css``;
  }};
  :hover {
    box-shadow: inset 1px 0px 20px 20px #ffffff32;
  }
  :active {
    box-shadow: inset 1px 0px 20px 20px #ffffff48;
  }
`;

const MiniButtonWrapper = styled.div`
  margin-left: -10px;
  margin-top: -2px;
  position: relative;
  z-index: 1;
`;

const WrapperIcon = styled.div`
  width: 32px;
  margin: 0 15px;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const SocialContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 220px;
`;

export default {
  Button,
  SocialButton,
  MiniButtonWrapper,
  SocialContent,
  WrapperIcon,
  LinkedButton,
  VouchersSelected,
};
