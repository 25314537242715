import React from 'react';
import * as S from './style';

const Privacy = () => (
  <S.Container>
    <h1>Política de Privacidade</h1>
    <br />
    <br />
    <br />
    As políticas de privacidade são parte integrante dos Termos de Uso do site
    UFFA, com sede a Rua Joaquim Floriano, 466, Sala 2002, Itaim Bibi, São Paulo
    / SP – CEP 04.534-002, inscrito no CNPJ sob o nº 33.311.666/0001-57, tem
    como base o compromisso de transparência com os USUÁRIOS e EMPRESAS
    PARCEIRAS no que tange à utilização do site{' '}
    <a href="www.uffa.com.vc">www.uffa.com.vc</a> e por finalidade estabelecer
    as regras sobre obtenção, uso e armazenamento dos dados e informações
    coletadas dos visitantes.
    <br />
    <br />
    <h1>Termos utilizados</h1>
    <br />
    Site: portal www.uffa.com.vc
    <br />
    <br />
    Visitantes ou Usuários: Pessoas que acessam ou interagem com o Site
    <br />
    <br />
    Cookies: Arquivos enviados para o computador dos visitantes, com a
    finalidade de identificar o computador e obter dados de acesso, como páginas
    navegadas ou links clicados, permitindo, desta forma, personalizar a
    navegação dos visitantes de acordo com o seu perfil.
    <br />
    <br />
    IP: Abreviatura de Internet Protocol. É um conjunto de números que
    identifica o computador dos visitantes na Internet.
    <br />
    <br />
    Logs: Registros de atividades dos visitantes efetuadas no Site.
    <br />
    <br />
    Cadastro: no Uffa
    <br />
    <br />
    <br />
    <br />
    <h1>
      1. O UFFA se preocupa com a segurança dos USUÁRIOS e EMPRESAS PARCEIRAS
      quando do acesso ao portal, garantindo a proteção das informações pessoais
      de todos aqueles que o acessam. No entanto, o USUÁRIO deve estar ciente de
      que as medidas de segurança relativas à internet não são integralmente
      infalíveis;
    </h1>
    <br />
    <br />
    <span>O QUE ISSO QUER DIZER?</span> Como sabemos, as medidas de segurança
    online não são 100% seguras, mas FIQUE TRANQUILO, o UFFA faz tudo que está
    ao seu alcance para proteger os seus dados e informações.
    <br />
    <br />
    <br />
    <br />
    <h1>
      2. Com a aceitação da Política de Privacidade e Termos de Uso do Site, o
      USUÁRIO tem ciência e CONCORDA que os seus dados pessoais, tais como nome,
      endereço, e-mail e telefones, bem como quaisquer outros dados por nós
      solicitados ou coletados durante o período de uso da plataforma pelo
      USUÁRIO,{' '}
      <strong>
        INCLUINDO OS DADOS AUDIOVISUAIS COLETADOS ATRAVÉS DA CÂMERA E MICROFONE
        DO DISPOSITIVO
      </strong>{' '}
      (quando expressamente autorizado pelo USUÁRIO), serão utilizados para as
      finalidades a seguir apresentadas:
      <br />
      <br />
      a. Proporcionar a você mais segurança em sua navegação no Site;
      <br />
      <br />
      b. Melhorar sua experiência e as suas interações durante a navegação;
      <br />
      <br />
      c. Identificar o seu perfil para permitir a oferta de informações ou
      serviços mais adequados às suas necessidades ou interesses;
      <br />
      <br />
      d. Para fins estatísticos, com o intuito de medir e analisar a audiência
      do Site e suas características, podendo essa análise e medição ser
      efetuada por empresas contratadas pelo UFFA;
      <br />
      <br />
      e. Subsidiar análises de riscos de crédito;
      <br />
      <br />
      f. Oferecer serviços/produtos e possibilitar nossas ações de marketing
      e/ou de nossos clientes e parceiros, bem como para fins de pesquisa de
      satisfação e para campanhas com ofertas promocionais de nossas soluções
      e/ou de nossos clientes e parceiros.
    </h1>
    <br />
    <br />
    <span>O QUE ISSO QUER DIZER?</span> Todos os seus dados coletados pelo UFFA
    serão analisados e utilizados para: melhorar a sua experiência no site;
    personalizar os serviços e produtos que oferecemos a você; e subsidiar
    análises de crédito.
    <br />
    <br />
    <br />
    <br />
    <h1>
      3. O USUÁRIO tem ciência que todos os registros de acesso e dados pessoais
      obedecem aos direitos à privacidade, à proteção de dados e ao sigilo das
      comunicações, nos termos da legislação vigente, portanto concorda e
      AUTORIZA que todos os dados pessoais disponibilizados em seu cadastro,
      sejam recebidos, tratados e armazenados pelo UFFA, podendo o USUÁRIO, a
      qualquer momento, requerer que essas informações sejam excluídas
      definitivamente;
    </h1>
    <br />
    <br />
    <span>O QUE ISSO QUER DIZER?</span> O UFFA respeita toda a legislação
    vigente para proteção dos seus dados quando os utiliza e você pode A
    QUALQUER MOMENTO solicitar que essas informações sejam excluídas.
    <br />
    <br />
    <br />
    <br />
    <h1>
      4. O USUÁRIO tem ciência de que o UFFA pode coletar dados pessoais,
      eventualmente disponíveis publicamente ou fornecidos por empresas
      terceiras, com o intuito de analisar melhor o perfil do USUÁRIO e
      possibilitar uma experiência mais personalizada. Os dados eventualmente
      coletados dessas fontes serão tratados com mesma segurança e
      comprometimento que os dados coletados diretamente pelo Site;
    </h1>
    <br />
    <br />
    <span>O QUE ISSO QUER DIZER?</span> Para sempre melhorar a sua experiência,
    o UFFA pode relacionar os dados que você fornece com outros dados
    disponíveis publicamente ou fornecidos por empresas terceiras. Não se
    preocupe! Cuidaremos de todos os seus dados com o mesmo comprometimento.
    <br />
    <br />
    <br />
    <br />
    <h1>
      5. As informações serão recebidas pelo UFFA, tratadas e armazenadas de
      forma segura e íntegra, em ambiente controlado e de segurança, observado o
      estado da técnica disponível, respeitando o prazo legal de guarda, mesmo
      após o término da relação mantida com o USUÁRIO;
    </h1>
    <br />
    <br />
    <span>O QUE ISSO QUER DIZER?</span> O UFFA armazenará os seus dados depois
    que você deixar de usar a plataforma, mas continuaremos a protegê-los
    utilizando as melhores práticas de mercado.
    <br />
    <br />
    <br />
    <br />
    <h1>
      6. O UFFA compartilha as informações do USUÁRIO com parceiros operacionais
      como Instituições Financeiras que fazem a avaliação e a cotação das
      condições solicitadas e outros parceiros que apoiam o UFFA na execução dos
      produtos e serviços solicitados. Somos muito rigorosos com o
      compartilhamento das suas informações e, por isso, as informações que
      identificam o USUÁRIO (como nome, CPF, email e endereço) somente são
      compartilhadas quando elas são imprescindíveis para viabilizar a execução
      dos produtos e serviços solicitados
    </h1>
    <br />
    <br />
    <span>O QUE ISSO QUER DIZER?</span> Em alguns casos precisaremos
    compartilhar os seus dados com os nossos parceiros para possibilitar o
    fornecimento de alguns serviços. Fazemos isso apenas quando estritamente
    necessário.
    <br />
    <br />
    <br />
    <br />
    <h1>
      7. Os arquivos de imagens e áudio eventualmente coletados pelo UFFA serão
      mantidos em nossa base de dados e não serão cedidos ou transferidos a
      terceiros, podendo, somente, serem comercializados relatórios com base
      nessas informações, com as finalidades previstas no item 2.a;
    </h1>
    <br />
    <br />
    <span>O QUE ISSO QUER DIZER?</span> Sabe as imagens e áudios que o UFFA
    coleta para tornar a sua navegação cada vez melhor? Ninguém terá acesso a
    elas.
    <br />
    <br />
    <br />
    <br />
    <h1>
      8. Utilizamos Cookies em diferentes partes do site para tornar a visita
      mais atraente e possibilitar o uso de certas funções. Cookies são pequenos
      arquivos de texto que são armazenados no computador quando do acesso a
      determinado site. A maioria dos Cookies utilizados em nosso site serão
      apagados do disco rígido do computador do USUÁRIO automaticamente ao
      encerrar a sessão do navegados. Outros tipos de Cookies permanecerão no
      computador e possibilitam a identificação do mesmo na próxima visita do
      nosso site. Salientamos que as EMPRESAS PARCEIRAS não são autorizadas a
      levantar, utilizar e processar dados pessoais obtidos por meio de Cookies
      do nosso site.
    </h1>
    <br />
    <br />
    <span>O QUE ISSO QUER DIZER?</span> Nós utilizamos Cookies em nosso site
    para deixar a sua experiência ainda mais personalizada. Nenhum de nossos
    parceiros tem acesso às informações coletadas.
    <br />
    <br />
    <br />
    <br />
    <h1>
      9. O UFFA registrará todas as atividades efetuadas pelos visitantes, por
      meio de logs, incluindo, mas não se limitando a:
      <br />
      <br />
      a. Endereço IP;
      <br />
      <br />
      b. Ações efetuadas pelos visitantes;
      <br />
      <br />
      c. Datas e horários de cada ação e de acesso a cada página; e
      <br />
      <br />
      d. Informações sobre o dispositivo utilizado;
    </h1>
    <br />
    <br />
    <span>O QUE ISSO QUER DIZER?</span> Você sabe que queremos sempre deixar a
    sua experiência a melhor possível, então armazenamos algumas informações
    sobre as suas atividades em nosso site para podermos analisar o seu
    comportamento dentro da plataforma.
    <br />
    <br />
    <br />
    <br />
    <h1>
      10. O UFFA utiliza diferentes sistemas de tracking de terceiros, como por
      exemplo, o Google Analytics, um serviço de análise de internet da Google
      Inc. O Google Analytics utiliza Cookies que são armazenados no computador
      do USUÁRIO e possibilitam a análise de utilização do site. As informações
      geradas a partir dos Cookies a respeito da utilização do site (incluído o
      seu endereço IP) serão transmitidas a um servidor nos Estados Unidos e lá
      armazenados;
    </h1>
    <br />
    <br />
    <span>O QUE ISSO QUER DIZER?</span> O Google coleta algumas informações
    sobre a sua navegação. Essas informações são armazenadas em um servidor do
    Google nos Estados Unidos.
    <br />
    <br />
    <br />
    <br />
    <h1>
      11. O Google utilizará essas informações para analisar a utilização do
      site para fornecer relatórios sobre a utilização aos operadores de sites
      bem como o fornecimento de outros serviços ligados à utilização do site e
      da internet. O Google transmitirá também essas informações a terceiros,
      caso seja imposto por lei ou no caso em que o terceiro processe esses
      dados por ordem do Google. O Google não ligará o endereço IP do USUÁRIO a
      outros dados;
    </h1>
    <br />
    <br />
    <span>O QUE ISSO QUER DIZER?</span> Com as informações coletadas o Google
    fornece um relatório sobre a utilização do site.
    <br />
    <br />
    <br />
    <br />
    <h1>
      12. O USUÁRIO poderá impedir a instalação de Cookies configurando seu
      navegador. Avisamos que neste caso a totalidade das funções do site não
      ficará disponível;
    </h1>
    <br />
    <br />
    <span>O QUE ISSO QUER DIZER?</span> Sabe os Cookies que nós comentamos? Você
    pode configurar o seu navegador para impedir a coleta dos dados, mas isso
    vai impedir que alguns serviços funcionem completamente.
    <br />
    <br />
    <br />
    <br />
    <h1>
      13. Ao utilizar o UFFA, o USUÁRIO concorda com o processamento dos dados
      levantados pelo Google na forma e na finalidade anteriormente descrita;
    </h1>
    <br />
    <br />
    <span>O QUE ISSO QUER DIZER?</span> Quando você utiliza o UFFA você
    automaticamente autoriza que alguns dados sejam analisados pelo Google
    Analytics.
    <br />
    <br />
    <br />
    <br />
    <h1>
      14. O UFFA fica autorizado a utilizar e processar os dados pessoais do
      USUÁRIO para fins de publicidade (e-mail, newsletter etc.);
    </h1>
    <br />
    <br />
    <span>O QUE ISSO QUER DIZER?</span> Pode ser que enviemos alguns e-mails
    para você. Sempre com novidades e notícias relevantes.
    <br />
    <br />
    <br />
    <br />
    <h1>
      15. O USUÁRIO poderá cancelar a sua inclusão nestes programas de
      publicidade a todo momento, por exemplo, enviando um e-mail ao endereço
      contato@uffa.com.vc, ou através do link disponível no e-mail encaminhado;
    </h1>
    <br />
    <br />
    <span>O QUE ISSO QUER DIZER?</span> Se não quiser receber os e-mails, você
    pode cancelar o recebimento através de um link na mensagem ou solicitando
    para nós em contato@uffa.com.vc.
    <br />
    <br />
    <br />
    <br />
    <h1>
      16. O UFFA fica autorizado a transmitir os dados do USUÁRIO a terceiros
      caso isto seja necessário para cumprir regras gerais legais, bem como no
      caso da transmissão de dados a autoridades policiais e judiciárias, para
      fins de persecução penal, ordem judicial, ou qualquer outra da mesma
      natureza, para fins de colaboração na investigação de crimes contra a
      segurança pública ou que envolva interesse maior;
    </h1>
    <br />
    <br />
    <span>O QUE ISSO QUER DIZER?</span> É uma situação bem incomum, mas se um
    juiz ou autoridade policial solicitar, o UFFA é obrigado a compartilhar os
    seus dados.
    <br />
    <br />
    <br />
    <br />
    <h1>
      17. Em conformidade com o ordenamento jurídico vigente, o USUÁRIO tem,
      além de outros direitos, o direito à informação gratuita sobre os dados
      armazenados e a eventual correção, bloqueio ou eliminação desses dados,
      salvo as hipóteses de manutenção previstas na presente Política de
      Privacidade e no Termo de Uso, basta enviar e-mail para o endereço
      contato@uffa.com.vc;
    </h1>
    <br />
    <br />
    <span>O QUE ISSO QUER DIZER?</span> A qualquer momento você pode solicitar
    que o UFFA de te envie, ou exclua qualquer informação que tenhamos sobre
    você.
    <br />
    <br />
    <br />
    <br />
    <h1>
      18. Todos os textos, imagens, sons e/ou aplicativos exibidos no Site são
      protegidos pelos direitos autorais, não sendo permitido modificar,
      reproduzir, armazenar, transmitir, copiar, distribuir ou utilizar esses
      recursos de quaisquer outras formas para fins comerciais sem o
      consentimento prévio e formal do UFFA;
    </h1>
    <br />
    <br />
    <span>O QUE ISSO QUER DIZER?</span> O conteúdo que compõe o nosso site é
    protegido por direitos autorais, então você não pode utilizá-lo sem o nosso
    consentimento.
    <br />
    <br />
    <br />
    <br />
    <h1>
      19. As disposições constantes desta Política de Privacidade poderão ser
      atualizadas ou modificadas a qualquer momento, cabendo aos visitantes
      verifica-la sempre que efetuarem o acesso ao Site. Navegar ou interagir
      com o Site após a atualização ou modificação das Políticas de Privacidade,
      será interpretado como concordância tácita às novas regras adotadas;
    </h1>
    <br />
    <br />
    <span>O QUE ISSO QUER DIZER?</span> Pode ser que essa política se altere,
    isso é normal, as vezes precisamos fazer algumas adequações, então é
    importante que você lembre de verifica-la sempre que acessar o site.
    <br />
    <br />
    <br />
    <br />
    Para obter maiores informações escreva para:{' '}
    <a href="mailto:contato@uffa.com.vc">contato@uffa.com.vc</a>.
    <br />
    <br />
    <br />
    <br />
    30 de novembro de 2019
    <br />
  </S.Container>
);

export default Privacy;
