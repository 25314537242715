/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/min/moment-with-locales';
import * as S from '../style';

import CatAvatar from '../../../assets/images/cat_avatar.png';
import UffaAvatar from '../../../assets/images/uffa_avatar.png';
import IconPencil from '../../../assets/icons/icon_pencil.svg';
import IconCancel from '../../../assets/icons/icon_cancel.svg';

const MessageBox = ({ messageItem }) => {
  moment.locale('pt-br');
  const [isTyping, setIsTyping] = React.useState(messageItem.uffa);
  const [isBallonEditable, setBallonEditable] = React.useState(false);
  const [animate, setAnimate] = React.useState('');
  const [fromNow, setFromNow] = React.useState();
  const [editText, setEditText] = React.useState(messageItem.text);
  const [messageText, setMessageText] = React.useState(messageItem.text);

  let interval = null;
  React.useEffect(() => {
    if (messageItem.uffa) {
      setTimeout(() => {
        setIsTyping(false);
        if (messageItem.callback) messageItem.callback();
      }, messageItem.typingDelay);
    }

    setFromNow(moment(messageItem.timestamp).fromNow());
    setAnimate('in');
    interval = setInterval(
      () => setFromNow(moment(messageItem.timestamp).fromNow()),
      3000
    );

    return () => clearInterval(interval);
  }, []);

  return (
    <S.MessageContainer className={animate} uffa={messageItem.uffa}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: messageItem.uffa ? 'row' : 'row-reverse',
        }}
      >
        <S.Avatar>
          <img
            style={{ width: 40, height: 40 }}
            src={messageItem.uffa ? UffaAvatar : CatAvatar}
            alt="Avatar"
          />
        </S.Avatar>
        <S.MessageScale>
          <S.MessageText>
            {messageItem.uffa && isTyping ? (
              <S.MessageTyping>
                <S.MessageTypingDot />
                <S.MessageTypingDot />
                <S.MessageTypingDot />
              </S.MessageTyping>
            ) : isBallonEditable ? (
              <S.EditableInput
                autoFocus
                value={editText}
                maxLength={messageItem.editableBallon.maxLength}
                type={messageItem.editableBallon?.type || 'text'}
                onKeyDown={async ({ key }) => {
                  if (key == 'Enter') {
                    const ret = await messageItem.onEditMessage(editText);
                    setMessageText(ret);
                    setEditText(ret);
                    setBallonEditable(false);
                  }
                }}
                onChange={(e) => {
                  let text = e.target.value;
                  if (messageItem.editableBallon?.replaceEditorRegex) {
                    text = text.replace(
                      messageItem.editableBallon.replaceEditorRegex,
                      ''
                    );
                  }
                  setEditText(text);
                }}
              />
            ) : !messageItem.uffa &&
              messageItem.editableBallon?.type === 'password' ? (
              messageText
                .split('')
                .map(() => '*')
                .join('')
            ) : (
              messageText
            )}
            {messageItem.editableBallon &&
              messageItem.editableBallon.edit != false &&
              !messageItem.uffa &&
              (isBallonEditable ? (
                <S.BallonActionButton onClick={() => setBallonEditable(false)}>
                  <img
                    style={{ marginRight: 5, width: 24, marginLeft: -5 }}
                    src={IconCancel}
                    alt="Cancelar"
                  />{' '}
                  Cancelar
                </S.BallonActionButton>
              ) : (
                <S.BallonActionButton onClick={() => setBallonEditable(true)}>
                  <img
                    style={{ marginRight: 5, width: 18 }}
                    src={IconPencil}
                    alt="Editar"
                  />{' '}
                  Editar
                </S.BallonActionButton>
              ))}

            <S.MessageTextArrow />
          </S.MessageText>
          <S.MessageTimestamp
            style={{ visibility: isTyping ? 'hidden' : 'visible' }}
          >
            {fromNow}
          </S.MessageTimestamp>
        </S.MessageScale>
      </div>
      {(!isTyping &&
        messageItem.options &&
        messageItem.messageType === 'social-options' && (
          <S.SocialOptionButtonsWrapper>
            {messageItem.options.map((item) => (
              <S.SocialOptionButton
                key={item.label}
                onClick={() =>
                  messageItem.optionOnClick(item.value, item.label)
                }
                mini
                type={item.value.toLowerCase()}
              >
                {item.label}
              </S.SocialOptionButton>
            ))}
          </S.SocialOptionButtonsWrapper>
        )) ||
        (!isTyping &&
          messageItem.options &&
          (messageItem.messageType === 'options' ||
            messageItem.messageType === 'reply-options') && (
            <S.OptionButtonsWrapper>
              {messageItem.options.map((item) => (
                <S.OptionButton
                  key={item.label}
                  onClick={() =>
                    messageItem.optionOnClick(item.value, item.label)
                  }
                  customBg={item.bgColor}
                >
                  {item.label}
                </S.OptionButton>
              ))}
            </S.OptionButtonsWrapper>
          ))}
    </S.MessageContainer>
  );
};

MessageBox.propTypes = {
  messageItem: PropTypes.object.isRequired,
};

export default MessageBox;
